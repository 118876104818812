import React, { useState, useEffect, useRef } from 'react';
import InteractionMessage from '../InteractionMessage';
import './style.scss';
import { Button } from 'react-bootstrap';
import MessageIcon from '@material-ui/icons/Message';
import { getCommentsByEntity, addCommentByEntity, deleteCommentByEntity, reportCommentByEntity, updateCommentByEntity } from 'services/api';
import ModalInteractionDrawer from 'pctComponents/modals/ModalInteractionDrawer';
import { formatDate, getProfileInfo } from 'services/utils';
import SendMessageBox from 'pctComponents/molecules/SendMessageBox';
import { EntityTypeEnum } from './InteractionDrawer.types';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { toast } from 'react-toastify';

type Props = {
    idEntity: string;
    entityType: EntityTypeEnum;
};
const COMMENTS_PER_PAGE = 6;

const skeleton = (qtd: number = 5) => {
    const html: JSX.Element = (
        <div className="skeletonMessage">
            <div className="skeletonAvatar"></div>
            <div className="skeletonTitle"></div>
            <div className="skeletonContent">
                <div className="skeletonText1"></div>
                <div className="skeletonText2"></div>
            </div>
            <hr className="skeletonDivider"></hr>
        </div>
    );
    const list: any = [];
    for (let i = 0; i < qtd; i++) {
        list.push(html);
    }
    return list;
};

const InteractionContent = ({ entityType, idEntity }: Props) => {
    const isMobile = useMediaQuery('md');
    const [page, setPage] = useState<number>(1);
    const [pagesGet, setPagesGet] = useState<any>([1]);
    const [totalComments, setTotalComments] = useState<number>(0);
    const [comments, setComments] = useState<any>([]);
    const [commentsParents, setCommentsParents] = useState<any>([]);
    const userProfile = getProfileInfo();
    const [menuOpitions, setMenuOpitions] = useState({
        title: 'Teste título',
        textButton: 'Excluir',
        show: false,
        action: '',
        type: '',
        content: '',
        message: '',
        idComment: '',
        replyEditText: '',
        setShow: (data) => {
            setMenuOpitions({ ...menuOpitions, show: data });
        },
        setData: (data) => {
            setMenuOpitions({ ...menuOpitions, ...data });
        },
    });
    const [showNewMessageBox, setShowNewMessageBox] = useState(isMobile);
    const [newMessage, setNewMessage] = useState('');
    const [commentEdit, setCommentEdit] = useState<any>('');
    const [loading, setLoading] = useState<any>(true);

    const loader = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [comments]);

    const verfyPage = (p) => {
        if (!pagesGet.includes(p)) {
            setPagesGet(pagesGet.concat(p));
            return true;
        }
        return false;
    };

    const handleObserver = (entries: any, observer: any) => {
        if (entries[0].isIntersecting) {
            observer.disconnect();
            let p = page + 1;
            if (!verfyPage(p)) {
                p++;
            }
            if (comments.length < totalComments - 1) {
                setPage(p);
            }
        }
    };

    const filterComentById = () => {
        let filterCommentResult = [];
        comments.forEach((comment: any) => {
            if (comment.idComment == menuOpitions.idComment) {
                filterCommentResult = comment;
            }
            comment?.replies.forEach((reply) => {
                if (reply.idComment == menuOpitions.idComment) {
                    filterCommentResult = comment;
                }
            });
        });
        return filterCommentResult;
    };

    const modalConfirm = async () => {
        if (menuOpitions.action == 'report') {
            await reportCommentByEntity(entityType, idEntity, { idComment: menuOpitions.idComment });
            menuOpitions.setData({
                title: 'Agradecemos por denunciar',
                content: 'Analisaremos sua denúncia e, se houver uma violação de nossas Diretrizes da Comunidade, tomaremos as medidas necessárias.',
                show: true,
                type: 'success',
            });
        }
        if (menuOpitions.action == 'delete') {
            await deleteCommentByEntity(entityType, idEntity, menuOpitions.idComment);
            menuOpitions.setShow(false);
            let textToast = menuOpitions.type == 'reply' ? 'Resposta excluída!' : 'Comentário excluído!';
            toast.success(textToast, { position: 'top-left', className: 'interactionToast' });
        }
        updateList();
    };
    const modalCancel = () => {
        menuOpitions.setShow(false);
    };

    const getComments = async (offset: number, limit: number, type: string = 'list') => {
        setLoading(true);
        const commentsList = await getCommentsByEntity(entityType, idEntity, { offset, limit });
        setTotalComments(commentsList?.data?.totalRecords);

        let list = commentsList?.data?.comments;
        const _comments = list?.map((comment: any) => {
            comment.replies = commentsParents[comment.idComment] ? commentsParents[comment.idComment].list : [];
            const myComments = comment.createdBy === userProfile.idUser;
            let newList = {
                ...comment,
                time: formatDate(comment.creationDate),
                commentsCount: comment.totalReplies,
                warningsCount: comment.totalReports,
                showHide: true,
                removeButton: myComments,
                reportButton: !myComments,
                editButton: myComments,
                hiddenBy: myComments,
                replies: formatReply(comment.replies),
            };

            return newList;
        });

        let newComments: any = _comments;
        if (type == 'reload') {
            //
        } else {
            newComments = comments.concat(newComments);
        }
        setComments(newComments);
        setLoading(false);
    };

    const getParentComments = async (idParentComment: number, offset: number, limit: number, callback = (comments: any) => {}) => {
        const commentsList = await getCommentsByEntity(entityType, idEntity, { idParentComment, offset, limit });
        callback({ list: commentsList?.data?.comments, total: commentsList?.data?.totalRecords } || []);
    };

    function removeDuplicatesCommentsKeepLast(arr) {
        const map = new Map();

        arr.forEach((item) => {
            map.set(item.idComment, item);
        });

        return Array.from(map.values());
    }

    const handleReplyList = async (idParentComment: number, customLimit: number | null = null) => {
        let verifyCommentParent = Boolean(commentsParents[idParentComment] || false);
        const limit = Number(customLimit || 5);
        var pageParent = verifyCommentParent ? commentsParents[idParentComment].page + 1 : 1;
        const offset = verifyCommentParent ? ((pageParent - 1) * limit) % commentsParents[idParentComment].total : 0;
        const refresh = Boolean(customLimit);

        await getParentComments(idParentComment, customLimit ? 0 : offset, limit, (response: any) => {
            let newList = formatReply(response.list);
            if (refresh) {
                commentsParents[idParentComment].list = newList;
            } else {
                commentsParents[idParentComment] = {
                    page: pageParent,
                    list: verifyCommentParent
                        ? removeDuplicatesCommentsKeepLast(commentsParents[idParentComment].list.concat(newList))
                        : removeDuplicatesCommentsKeepLast(newList),
                    total: response.total,
                };
            }
            setCommentsParents(commentsParents);
            updateList();
        });
    };

    const handlerMoreAnswers = (idParentComment: number) => {
        handleReplyList(idParentComment);
    };
    const handlerHideAnswers = (idParentComment: number) => {
        delete commentsParents[idParentComment];
        setCommentsParents(commentsParents);
        updateList();
    };

    const formatReply = (replies) => {
        return replies?.map((reply) => ({
            ...reply,
            text: reply.text || '',
            time: formatDate(reply?.creationDate),
            commentsCount: 0,
            warningsCount: reply?.totalReports,
            showHide: true,
            removeButton: reply?.createdBy === userProfile?.idUser,
            editButton: reply?.createdBy === userProfile?.idUser,
            hiddenBy: reply?.hiddenBy === userProfile?.idUser,
            reportButton: reply?.createdBy !== userProfile?.idUser,
            replies: [],
        }));
    };

    const updateList = () => {
        getComments(0, comments.length + 1, 'reload');
    };

    useEffect(() => {
        const offset = ((page - 1) * COMMENTS_PER_PAGE) % (totalComments - 1);
        getComments(offset, COMMENTS_PER_PAGE);
    }, [page]);

    useEffect(() => { 
        if (menuOpitions.action == 'edit') {
            let currentComment: any = filterComentById();
            setCommentEdit(currentComment);
            if (menuOpitions.type !== 'replyEdit') {
                setShowNewMessageBox(true);
                setNewMessage(menuOpitions.replyEditText);
            }
        }
    }, [menuOpitions]);

    const handleNewMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleNewMessageSubmit = async (newText) => {
        setNewMessage('');
        setShowNewMessageBox(false);
        setCommentEdit('');
        if (commentEdit) {
            await updateCommentByEntity(entityType, idEntity, commentEdit?.idComment, { text: newText });
        } else {
            await addCommentByEntity(entityType, idEntity, { text: newText });
        }
        updateList();
    };

    const handleReplySubmit = async (idParentComment: number, action: string, message: string) => {
        if (menuOpitions.type == 'replyEdit' && idParentComment) {
            await updateCommentByEntity(entityType, idEntity, menuOpitions.idComment, { text: message });
        } else if (action == 'reply' && idParentComment) {
            await addCommentByEntity(entityType, idEntity, { text: message, idParentComment: idParentComment });
        } else {
            return false;
        }
        updateList();
        menuOpitions.setData({ type: '' });
        setNewMessage('');
        setShowNewMessageBox(false);
        setCommentEdit('');
        if (commentsParents[idParentComment]) {
            handleReplyList(idParentComment, commentsParents[idParentComment].list.length);
        }
    };

    return (
        <>
            <ModalInteractionDrawer
                className={'darkDilog modal-' + menuOpitions?.type}
                title={menuOpitions.title}
                htmlBody={menuOpitions.content}
                btn1Info={{ event: modalCancel, text: menuOpitions.type == 'success' ? 'Concluído' : 'Cancelar', className: 'btn-success' }}
                btn2Info={
                    menuOpitions.type == 'success'
                        ? undefined
                        : {
                              event: modalConfirm,
                              text: menuOpitions.textButton,
                              className: 'active',
                          }
                }
                show={menuOpitions.show}
                setShow={menuOpitions.setShow}
            />

            {!isMobile && (
                <>
                    <div className="interaction-drawer-actions">
                        <div className="interaction-drawer-button-container">
                            {!showNewMessageBox && (
                                <Button className="interaction-drawer-button" onClick={() => setShowNewMessageBox(true)}>
                                    Adicionar mensagem
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className={`new-message-box`}>
                        {showNewMessageBox && (
                            <SendMessageBox
                                isReply={false}
                                placeholder={`Digite sua mensagem...`}
                                isEditing={Boolean(commentEdit)}
                                editContent={newMessage}
                                handleToggle={() => {
                                    setShowNewMessageBox(false);
                                    setNewMessage('');
                                    setCommentEdit('');
                                }}
                                handleSubmit={handleNewMessageSubmit}
                            />
                        )}
                    </div>
                </>
            )}

            {isMobile && (
                <div className={`new-message-box-mobile`}>
                    {isMobile && Boolean(commentEdit) && menuOpitions.action === 'edit' && (
                        <div className="edit-replying-to-cancel-button-mobile">
                            <div>
                                <span className="reply-mention-text">{Boolean(commentEdit) ? 'Editando' : ''}</span>
                            </div>
                            <button
                                onClick={() => {
                                    setCommentEdit('');
                                    setNewMessage('');
                                }}
                                className="cancel-button-mobile"
                            >
                                Cancelar
                            </button>
                        </div>
                    )}
                    {showNewMessageBox && (
                        <SendMessageBox
                            isReply={false}
                            placeholder={`Digite sua mensagem...`}
                            isEditing={Boolean(commentEdit)}
                            editContent={newMessage}
                            handleToggle={() => {
                                setShowNewMessageBox(false);
                                setNewMessage('');
                                setCommentEdit('');
                            }}
                            handleSubmit={handleNewMessageSubmit}
                        />
                    )}
                </div>
            )}

            <div
                id="elementoPai"
                className="interaction-drawer-comments"
                style={{ padding: !isMobile ? 20 : 0, overflow: !Boolean(comments?.length || 0) ? 'hidden' : 'auto' }}
            >
                {comments.map((comment: any, index, array) => (
                    <>
                        <InteractionMessage
                            key={index}
                            setShowNewMessageBox={setShowNewMessageBox}
                            showNewMessageBox={showNewMessageBox}
                            handleReplySubmit={handleReplySubmit}
                            handleReplyList={handleReplyList}
                            menuOpitions={menuOpitions}
                            avatar={comment.photo}
                            author={comment.name}
                            {...comment}
                            displayDivider={index < comments.length - 1}
                            onResize={() => {}}
                            handlerMoreAnswers={handlerMoreAnswers}
                            handlerHideAnswers={handlerHideAnswers}
                        />
                        {/* {index === array.length - 1 && <div style={{ height: 100 }} />} */}
                    </>
                ))}
                {!loading && comments.length === 0 && (
                    <div className="no-comments-container">
                        <p className="no-comments-text">
                            Não há interações na {entityType === EntityTypeEnum.NODE ? 'página' : 'turma'} ainda. Seja o primeiro!
                        </p>
                    </div>
                )}
                <div className="loading" ref={loader}></div>
                <div style={{ height: 115 }} />
                {loading && skeleton()}
            </div>
        </>
    );
};

export default InteractionContent;
