import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import sanitizeHtml from 'sanitize-html';
import TextEditor, { TextEditorHandle, getInnerText } from '../../atoms/TextEditor';
import './style.scss';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { Button } from 'react-bootstrap';

const MAX_CHAR_COUNT = 1000;

type SendMessageBoxProps = {
    isReply: boolean;
    isEditing: boolean;
    editContent: string;
    handleToggle: () => void;
    handleSubmit: (text: string) => void;
    placeholder: string;
};

const addTargetBlank = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const links = tempDiv.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
        links[i].setAttribute('target', '_blank');
        links[i].setAttribute('rel', 'noopener noreferrer');
    }
    return tempDiv.innerHTML;
};

const SendMessageBox = ({ isEditing = false, isReply = false, editContent, handleToggle, handleSubmit, placeholder = '' }: SendMessageBoxProps) => {
    const isMobile = useMediaQuery('md');
    const editorRef = useRef<TextEditorHandle>(null);
    const [content, setContent] = useState(editContent);

    const handleSave = () => {
        if (!editorRef.current) {
            return;
        }

        const htmlContent = editorRef.current.getContent();

        const cleanHtmlContent = sanitizeHtml(htmlContent, {
            allowedTags: ['b', 'i', 'u', 'a', 'p', 'br'],
            allowedAttributes: {
                a: ['href'],
            },
            allowedSchemes: ['http', 'https', 'mailto'],
        });

        const finalHtmlContent = addTargetBlank(cleanHtmlContent);

        const truncatedContent = finalHtmlContent.substring(0, MAX_CHAR_COUNT);
        if (Boolean(getInnerText(truncatedContent).trim().length)) {
            setContent('');
            handleSubmit(truncatedContent);
        }

        editorRef.current?.clearContent();
    };

    return (
        <div className={'send-message-box'} style={{ padding: !isMobile ? 10 : 0 }}>
            <div className={'text-editor-container'}>
                <TextEditor
                    initialContent={editContent}
                    ref={editorRef}
                    placeholder={placeholder}
                    maxLength={MAX_CHAR_COUNT}
                    counter={!isMobile}
                    onChange={(newContent) => setContent(getInnerText(newContent))}
                />
                {isMobile && (
                    <Button
                        className="send-message-button-mobile"
                        onClick={handleSave}
                        style={{ opacity: Boolean(content.trim()?.length || 0) ? 1 : 0.5 }}
                        disabled={!Boolean(content.trim()?.length || 0)}
                    >
                        <FaPaperPlane />
                    </Button>
                )}
            </div>
            {!isMobile && (
                <div className="send-message-actions">
                    <button className="send-message-button send-message-cancel-button" onClick={handleToggle}>
                        Cancelar
                    </button>
                    <button
                        className="send-message-button send-message-submit-button"
                        onClick={handleSave}
                        disabled={!Boolean(content.trim()?.length || 0)}
                    >
                        {isEditing ? 'Editar' : 'Enviar'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default SendMessageBox;
