import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ButtonGroup, Button, Menu, MenuItem, TextField } from '@material-ui/core';
import { FaBold, FaItalic, FaUnderline, FaLink } from 'react-icons/fa';
import './styles.scss';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { toast } from "react-toastify";

export interface TextEditorProps {
    initialContent?: string;
    placeholder?: string;
    className?: string;
    maxLength?: number;
    onChange?: (content: string) => void;
    [key: string]: any;
}

export interface TextEditorHandle {
    getContent: () => string;
    clearContent: () => void;
}

const TextEditor = forwardRef<TextEditorHandle, TextEditorProps>(
    ({ initialContent = '', placeholder, className = '', counter = false, maxLength = 500, onChange, ...extraProps }, ref) => {
        const editorRef = useRef<HTMLDivElement>(null);
        const urlRef = useRef<HTMLDivElement>(null);
        const textRef = useRef<HTMLDivElement>(null);
        const menuRef = useRef(null);

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [linkURL, setLinkURL] = useState('');
        const [linkText, setLinkText] = useState('');
        const [content, setContent] = useState(initialContent);
        const [isBold, setIsBold] = useState(false);
        const [isItalic, setIsItalic] = useState(false);
        const [isUnderline, setIsUnderline] = useState(false);
        const [savedSelection, setSavedSelection] = useState<Range | null>(null);
        const isMobile = useMediaQuery('sm');
        useEffect(() => {
            return () => {
                setContent('');
                setIsBold(false);
                setIsItalic(false);
                setIsUnderline(false);
                setLinkText('');
                setLinkURL('');
            };
        }, []);

        useImperativeHandle(ref, () => ({
            getContent: () => editorRef.current?.innerHTML || '',
            clearContent: () => {
                setContent('');
                setIsBold(false);
                setIsItalic(false);
                setIsUnderline(false);
                if (editorRef.current) {
                    editorRef.current.innerHTML = '';
                }
            },
        }));

        const saveSelection = () => {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                setSavedSelection(range);
            }
        };

        const restoreSelection = () => {
            if (savedSelection) {
                const selection = window.getSelection();
                if (selection) {
                    selection.removeAllRanges();
                    selection.addRange(savedSelection);
                }
            }
        };

        const applyStyle = (style: string) => {
            restoreSelection();
            document.execCommand(style);
            saveSelection();
            handleChange();
        };

        const toggleBold = () => {
            setIsBold(!isBold);
            applyStyle('bold');
        };

        const toggleItalic = () => {
            setIsItalic(!isItalic);
            applyStyle('italic');
        };

        const toggleUnderline = () => {
            setIsUnderline(!isUnderline);
            applyStyle('underline');
        };

        const isValidURL = (url: string) => {
            const pattern = /^(https?:\/\/|www\.)\S+/i;
            return pattern.test(url);
        };

        const insertLink = (url: string, text: string) => {
            restoreSelection();

            const selection: any = window.getSelection();
            if (!isValidURL(url)) {
                toast.error('Formato de url inválido');
                return;
            }
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const linkHTML = `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
                range.deleteContents();
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = linkHTML;
                const frag = document.createDocumentFragment();
                let child;
                while ((child = tempDiv.firstChild)) {
                    frag.appendChild(child);
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                editorRef.current?.id === selection?.focusNode?.id ? range.insertNode(frag) : editorRef.current?.appendChild(frag);

                saveSelection();
            }
            handleChange();
        };

        const handleLink = (event: React.MouseEvent<HTMLButtonElement>) => {
            saveSelection();
            setAnchorEl(event.currentTarget);
        };

        const handleLinkSubmit = () => {
            if (linkURL) {
                insertLink(linkURL, linkText || linkURL);
            }
            handleLinkClose();
        };

        const handleLinkClose = () => {
            setAnchorEl(null);
            setLinkURL('');
            setLinkText('');
        };

        const handleChange = () => {
            if (editorRef.current) {
                const newContent = editorRef.current.innerHTML;
                setContent(newContent);
                if (onChange) {
                    onChange(newContent);
                }

                if (getInnerText(newContent).trim().length === 0) {
                    setIsBold(false);
                    setIsItalic(false);
                    setIsUnderline(false);
                }
            }
        };

        const handleInput = () => {
            handleChange();
        };

        const handleEnterPress = (event: React.KeyboardEvent) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                if (document.activeElement === urlRef.current) {
                    textRef.current?.focus();
                } else {
                    urlRef.current?.focus();
                }
            }
        };

        return (
            <div className="text-editor-wrapper">
                <div
                     contentEditable
                    id={'input-edit'}
                    ref={editorRef}
                    dangerouslySetInnerHTML={{
                        __html: initialContent,
                    }}
                    onInput={handleInput}
                    onBlur={saveSelection}
                    className={`form-control ${className} text-editor`}
                    style={{
                        minHeight: !isMobile ? '100px' : '47px',
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        width: '100%' /* Garante que o editor ocupe 100% da largura do contêiner */,
                    }}
                    data-placeholder={placeholder}
                    {...extraProps}
                    suppressContentEditableWarning={true}
                />
                <div
                    className="editor-toolbar"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                        marginBottom: 10,
                    }}
                >
                    <ButtonGroup>
                        <Button onClick={toggleBold} style={{ color: '#E2E2E2', backgroundColor: isBold ? 'gray' : 'transparent', border: 'none' }}>
                            <FaBold />
                        </Button>
                        <Button
                            onClick={toggleItalic}
                            style={{ color: '#E2E2E2', backgroundColor: isItalic ? 'gray' : 'transparent', border: 'none' }}
                        >
                            <FaItalic />
                        </Button>
                        <Button
                            onClick={toggleUnderline}
                            style={{ color: '#E2E2E2', backgroundColor: isUnderline ? 'gray' : 'transparent', border: 'none' }}
                        >
                            <FaUnderline />
                        </Button>
                        <Button onClick={handleLink} style={{ color: '#E2E2E2', border: 'none', marginLeft: 20 }}>
                            <FaLink />
                        </Button>
                    </ButtonGroup>
                    {counter && (
                        <span className="counterText" style={{ marginLeft: 'auto', color: '#E2E2E2' }}>
                            {getInnerText(content)?.length || 0}/{maxLength}
                        </span>
                    )}
                </div>
                <Menu
                 
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleLinkClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    keepMounted
                    ref={menuRef}
                    className="insertLinkBox"
                    getContentAnchorEl={null}
                    disableScrollLock
                >
                    <MenuItem>
                        <TextField
                            key='url'
                            type="text"

                            placeholder="URL"
                            inputRef={urlRef}
                            onKeyDown={handleEnterPress}
                            value={linkURL}
                            variant="outlined"

                            onChange={(e) => setLinkURL(e.target.value)}
                            autoFocus 
                        />
                    </MenuItem>
                    <MenuItem>
                        <TextField
                            key='texto'
                            type="text"
                            placeholder="Texto"
                            inputRef={textRef}
                            onKeyDown={handleEnterPress}
                            value={linkText}
                            onChange={(e) => setLinkText(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button onClick={handleLinkSubmit} color="primary" variant="contained">
                            Inserir
                        </Button>
                    </MenuItem>
                </Menu>
            </div>
        );
    },
);

export const getInnerText = (htmlContent: string) => {
    const htmlWithNewlines = htmlContent.replace(/<br\s*\/?>/gi, '\n');
    const doc = new DOMParser().parseFromString(htmlWithNewlines, 'text/html');
    const innerText = doc.body.innerText;
    return innerText || '';
};

export default TextEditor;
