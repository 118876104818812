import "./style.scss";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Label, Text, Image, Like } from "../../atoms";
import useSrcSetVerify from "../../../pctHooks/useSrcSetVerify";
import useCards from "../../../pctHooks/useCards";
import { useParams } from "react-router-dom";
import SVG from "react-inlinesvg";

type Props = {
  className?: string;
  css?: object;
  collection: any;
  dataParent: any;
  type?: string;
  format: string;
  effects: string;
};

type idType = {
  id?: string;
};

const CardNavigation = ({
  collection,
  dataParent,
  className = "",
  css = {},
  format = "portrait",
  effects = "",
}: Props) => {
  const { id } = useParams<idType>();
  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);

  const currentCollection = collection.data ? collection.data : collection;
  const { nodeCode, title, description, label, idElement, parameters } =
    currentCollection;
  const history = useHistory();

  const {
    image,
    concluidedText,
    verifyCompletedStatus,
    currentLabel,
    isEnrollment,
    canEnrollment,
    noTeamAvaliable,
    initialProgressState,
    evaluationEnrollment,
    statusRegistered,
    prerequisitesMet,
    routerLink,
    handleCardMouseEvent,
    handleCertificateMouseEvent,
    hasCertificate,
    currentBreadText,
    statusElement,
    hasEnrollment,
  } = useCards({
    collection,
    id,
    dataParent,
    setProgressStateRef,
    progressInfoRef,
  });

  // const handleMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
  //   const currentElementType = parameters
  //     ? parameters.elementType.toUpperCase()
  //     : collection.elementType
  //     ? collection.elementType.toUpperCase()
  //     : "NODE";
  //   let pathLink = "/";

  //   if (currentElementType === "NODE") {
  //     pathLink = `/catalog/${nodeCode}`;
  //   } else if (currentElementType === "CONTENT") {
  //     pathLink = `/contentNode/${idElement}/'-'`;
  //   }

  //   history.push(`${pathLink}`);
  // };

  const imgBase =
    statusElement() === "ARCHIVED" && image().image ? image().image : image();
  const srcSetVerify = useSrcSetVerify(imgBase, format);

  return (
    <div
      className="pctMolecule pctCard pctCardNavigation"
      style={css}
      onClick={(e) => {
        handleCardMouseEvent(e);
      }}
    >
      {currentCollection.image && (
        <div className="containerData" data-aspectratio={format}>
          <Like
            direction="invert"
            collection={collection}
            statusRegistered={statusRegistered()}
          />

          <Image
            path={image()}
            pathSet={srcSetVerify}
            cardEffect={effects === "shadow"}
          />
        </div>
      )}

      {!currentCollection.image && (
        <div className="containerData" data-aspectratio={format}>
          <Like
            direction="invert"
            collection={collection}
            statusRegistered={statusRegistered()}
          />

          <div className="thumb">{image() && <SVG src={image()} />}</div>
        </div>
      )}

      {title && <Text component="h4" text={title} className="titleCard" />}
      {description && (
        <Text component="p" text={description} className="description" />
      )}
      {label && <Label text={label} className={"label"} />}
    </div>
  );
};

export default CardNavigation;
