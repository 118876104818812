import { DialogProps } from "../@model/ManagerTrial.model";

export const dialogContainerModel = ( model: DialogProps ) => {
    const dialogData = {
        'ConfirmTrial': {
            title: `Parabéns, você acaba de Iniciar o trial do <span>Plano ${model.plan}</span>!`,
            subTitle: 'Verifique o seu e-mail para mais orientações de acesso.',  
            buttonConfirm: {
                text: 'Acesse seu trial',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ConfirmSucess': {
            title: `Obrigado sua mensagem foi enviada com sucesso!`,
            subTitle: 'Em breve, o nosso time comercial entrará em contato.',  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ConfirmPlan': {
            title: `Parabéns, você acaba de contratar o <span>Plano ${model.plan}</span>!`,
            subTitle: 'Caso queira consultar o seu plano, é só verificar na área de administração.',  
            buttonCancel: {
                text: 'Continuar na área do aluno',
                callEvent : model.buttonCancel?.callEvent
            },
            buttonConfirm: {
                text: 'Ir para área de adminstração',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'FinishTrial': {
            title: `Atenção!`,
            subTitle: `Seu período de trial do <span>Plano ${model.plan}</span> se <span>encerrou</span>.<br> A qualquer momento você pode continuar trabalhando na aprendizagem da sua empresa, basta contratar alguns de nossos planos.`,  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ExtendTrial': {
            title: `Parabéns, você acaba de estender o trial do <span>Plano ${model.plan}</span> por mais <span>${model.agreement} dia${model.agreement && model.agreement > 0 ? 's': ''}</span>.`,
            subTitle: `Aproveite ao máximo todas as funcionalidades que a ferramenta tem para lhe oferecer.`,  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
    }[model.type as string]

    return dialogData as DialogProps
}