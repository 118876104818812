import "./style.scss";

import React, { useContext, useState, useEffect } from "react";
import { getknowledgeEvaluationQuestionResult } from "../../../services/api";
import {
  EvaluationKnowledgeListAnswers,
  EvaluationKnowledgeCardFeedback,
} from "../index";

import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";
import { Preloader } from "pctComponents/atoms";

type Props = {};

const EvaluationKnowledgeFeedback = ({}: Props) => {
  const [evaluationResults, setEvaluationResults] = useState(null);
  const {
    contextCurrentEvaluation,
    contextCurrentIdUser,
    contextCurrentIdEnrollment,
    contextCurrentIdEvaluation,
    contextCurrentIdQuestionnaire,
    setContextCurrentAvaliationResult,
  } = useContext(CreateEvaluationKnowledgeContext);

  useEffect(() => {
    getknowledgeEvaluationQuestionResult(
      contextCurrentIdUser,
      contextCurrentIdEnrollment,
      contextCurrentIdEvaluation,
      contextCurrentIdQuestionnaire
    )
      .then((response) => {
        setTimeout(() => {
          setContextCurrentAvaliationResult(response.data);
          setEvaluationResults(response.data);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!evaluationResults) return <Preloader />;

  return (
    <div className="evaluationKnowledgeFeedback">
      <EvaluationKnowledgeCardFeedback />

      {contextCurrentEvaluation.parameters.showResponseSummary === "1" && (
        <EvaluationKnowledgeListAnswers />
      )}
    </div>
  );
};

export default EvaluationKnowledgeFeedback;
