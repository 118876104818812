import React, { useState, createContext, useRef } from "react";
import {
  setKnowledgeEvaluationQuestionAnswer,
  postQuestionnairesFinish,
  updateEvaluationTime,
} from "services/api";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../pctComponents/modals/ModalLoadingInfo";

// timeLeft
// :
// 60

export const CreateEvaluationKnowledgeContext = createContext();

export const ContextEvaluationKnowledge = ({ children }) => {
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const modalLoading = useModal("ModalLoadingInfo");

  const [motionCurrentIndex, setMotionCurrentIndex] = useState(0);
  const [contextCurrentEvaluation, setContextCurrentEvaluation] = useState({});
  const [contextCurrentAnswers, setContextCurrentAnswers] = useState([]);
  const [evaluationCurrentIndex, setEvaluationCurrentIndex] = useState(0);
  const [contextCurrentAvaliationResult, setContextCurrentAvaliationResult] =
    useState({});
  const [contextCurrentIdUser, setContextCurrentIdUser] = useState(0);
  const [contextCurrentIdEnrollment, setContextCurrentIdEnrollment] =
    useState(0);
  const [contextCurrentNodeEnrollment, setContextCurrentNodeEnrollment] =
    useState(0);
  const [contextCurrentIdEvaluation, setContextCurrentIdEvaluation] =
    useState(0);
  const [contextCurrentIdQuestionnaire, setContextCurrentIdQuestionnaire] =
    useState(0);

  const [contextQuestionnaireTimeLeft, setContextQuestionnaireTimeLeft] =
    useState(null);

  const contextGoToEvaluationScreenRef = useRef(false);

  const setContextGoToEvaluationScreen = (stateSet) => {
    contextGoToEvaluationScreenRef.current = stateSet;
  };

  const contextUpdateFooterRef = useRef(false);

  const setContextUpdateFooterRef = (stateSet) => {
    contextUpdateFooterRef.current = stateSet;
  };

  const contextGoToEvaluationScreen = (screenId) => {
    contextGoToEvaluationScreenRef.current(screenId);
  };

  const evaluationInitializedCurrentIndex = (_answers) => {
    let currentIndex = false;

    for (let index in _answers) {
      if (_answers[index].answerData.length > 0) {
        const currenIndex = Number(index);

        if (currenIndex + 1 < _answers.length) {
          currentIndex = currenIndex + 1;
        } else {
          currentIndex = currenIndex;
        }
      }
    }

    setEvaluationCurrentIndex(currentIndex);

    return currentIndex;
  };

  const getQuestionsNotAnswer = () => {
    let questionsNotAnswer = [];
    for (let index in contextCurrentAnswers) {
      if(contextCurrentAnswers[index]?.question?.questionType === 'CORRELATE'){
        if(contextCurrentAnswers[index].answerData.length < contextCurrentAnswers[index]?.question?.options.length){
          questionsNotAnswer.push(`questão ${Number(index) + 1}`);
        }
      }else
      if (contextCurrentAnswers[index].answerData.length == 0) {
        questionsNotAnswer.push(`questão ${Number(index) + 1}`);
      }
    }
    return questionsNotAnswer;
  };

  const currentQuestionIsAnswer = () => {
    let questionIsAnswer = false;

    if (evaluationCurrentQuestion().answerData.length > 0) {
      questionIsAnswer = true;
    }

    return questionIsAnswer;
  };

  const evaluationCurrentQuestion = () => {
    return contextCurrentAnswers[evaluationCurrentIndex];
  };

  const finishEvaluation = (finishEvent = null) => {
    setContextQuestionnaireTimeLeft(0);

    modalLoading.show({
      obj: {
        content: () => {
          return (
            <div className="alertText">
              <p>Aguarde, estamos processando o resultado.</p>
            </div>
          );
        },
        closeBtn: false,
        loadding: true,
        className: "evaluationsModal",
      },
    });

    postQuestionnairesFinish(
      null,
      contextCurrentIdUser,
      contextCurrentIdEnrollment,
      contextCurrentIdEvaluation,
      contextCurrentIdQuestionnaire
    )
      .then((response) => {
        setTimeout(() => {
          contextGoToEvaluationScreen("FEEDBACK");
          modalLoading.remove();

          if (finishEvent) finishEvent();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateEvaluationLeftTime = (leftTime) => {
    setContextQuestionnaireTimeLeft(leftTime);

    (async () => {
      const result = await updateEvaluationTime(
        contextCurrentIdUser,
        contextCurrentIdEnrollment,
        contextCurrentIdEvaluation,
        contextCurrentIdQuestionnaire,
        leftTime
      );
    })();
  };

  const evaluationGoToNextQuestion = () => {
    const timeMotion = 1000 * 0.5;
    setMotionCurrentIndex({
      indice: evaluationCurrentIndex + 1,
      time: timeMotion,
    });

    // setTimeout(() => {
    setEvaluationCurrentIndex(evaluationCurrentIndex + 1);
    // }, timeMotion);
  };

  const evaluationGoToPrevQuestion = () => {
    const timeMotion = 1000 * 0.5;
    setMotionCurrentIndex({
      indice: evaluationCurrentIndex - 1,
      time: timeMotion,
    });

    // setTimeout(() => {
    setEvaluationCurrentIndex(evaluationCurrentIndex - 1);
    // }, timeMotion);
  };

  const updateEvaliationAnswers = (currentQuestion, answerDataArr) => {
    let tempAnswers = contextCurrentAnswers;
    tempAnswers[evaluationCurrentIndex]["answerData"] = answerDataArr;

    setContextCurrentAnswers(tempAnswers);

    let dataRequest = currentQuestion;
    dataRequest.answerData = answerDataArr;

    // console.log(dataRequest);

    (async () => {
      const result = await setKnowledgeEvaluationQuestionAnswer(
        contextCurrentIdUser,
        contextCurrentIdEnrollment,
        contextCurrentIdEvaluation,
        contextCurrentIdQuestionnaire,
        currentQuestion.idAnswer,
        dataRequest
      );

      // console.log(result, contextCurrentAnswers);
      //console.log(result);
    })();
  };

  const value = {
    modalLoading,

    motionCurrentIndex,
    setMotionCurrentIndex,

    contextCurrentEvaluation,
    setContextCurrentEvaluation,

    contextCurrentAnswers,
    setContextCurrentAnswers,

    setEvaluationCurrentIndex,

    setContextGoToEvaluationScreen,
    contextGoToEvaluationScreenRef,
    contextGoToEvaluationScreen,

    evaluationInitializedCurrentIndex,
    evaluationCurrentIndex,

    contextCurrentIdUser,
    setContextCurrentIdUser,

    contextCurrentIdEnrollment,
    setContextCurrentIdEnrollment,

    contextCurrentNodeEnrollment,
    setContextCurrentNodeEnrollment,

    contextCurrentIdEvaluation,
    setContextCurrentIdEvaluation,

    contextCurrentIdQuestionnaire,
    setContextCurrentIdQuestionnaire,

    evaluationCurrentQuestion,
    evaluationGoToNextQuestion,
    evaluationGoToPrevQuestion,

    contextCurrentAvaliationResult,
    setContextCurrentAvaliationResult,

    getQuestionsNotAnswer,
    currentQuestionIsAnswer,

    updateEvaliationAnswers,
    setContextUpdateFooterRef,
    contextUpdateFooterRef,

    setContextQuestionnaireTimeLeft,
    contextQuestionnaireTimeLeft,
    updateEvaluationLeftTime,

    finishEvaluation,
  };

  return (
    <CreateEvaluationKnowledgeContext.Provider value={value}>
      {children}
    </CreateEvaluationKnowledgeContext.Provider>
  );
};
