import "./style.scss";

import React, { useState, useEffect, useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { getProfileInfo } from "services/utils";
import { FiCheckSquare } from "react-icons/fi";

import { updateConfigHeaderFooter } from "../../../actions";
import { useFetch } from "../../../pctHooks/useFetch";
import {
  postQuestionnairesSave,
  postQuestionnairesFinish,
} from "../../../services/api";
import { Preloader, Text } from "../../atoms";

import {
  EvaluationKnowPlayer,
  EvaluationKnowledgeFeedback,
  EvaluationKnowledgeIntroduction,
} from "../../molecules";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import useBackControll from "../../../pctHooks/useBackControll";
import { getKnowledgeEvaluationDetails } from "../../../services/api";
import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";
import { type } from "os";

type Props = {
  fetchData: any;
  idEnrollment: any;
  idEvaluation: any;
  idQuestionnaire: any;
  idNodeEnrollment: any;
  finishEvent?: any;
};
const EvaluationKnowledge = ({
  fetchData,
  idEnrollment,
  idEvaluation,
  idQuestionnaire,
  idNodeEnrollment,
  finishEvent,
}: Props) => {
  const userProfile = getProfileInfo();
  const history = useHistory();
  const { backControllFn } = useBackControll({});
  const { answers, evaluation, finishDate, timeLeft } = fetchData;
  const {
    setContextCurrentEvaluation,
    setContextCurrentAnswers,
    contextCurrentAnswers,
    setContextGoToEvaluationScreen,
    evaluationInitializedCurrentIndex,
    setContextCurrentIdUser,
    setContextCurrentIdEnrollment,
    setContextCurrentNodeEnrollment,
    setContextCurrentIdEvaluation,
    setContextCurrentIdQuestionnaire,
    setContextQuestionnaireTimeLeft,
  } = useContext(CreateEvaluationKnowledgeContext);

  const [contextRegistration, setContextRegistration] = useState(false);
  const [
    evaluationKnowledgeCurrentComponent,
    setEvaluationKnowledgeCurrentComponent,
  ] = useState("");

  useEffect(() => {
    setContextCurrentEvaluation(evaluation);
    setContextCurrentAnswers(answers);
    setContextCurrentIdUser(userProfile.idUser);
    setContextCurrentIdEnrollment(idEnrollment);
    setContextCurrentNodeEnrollment(idNodeEnrollment);
    setContextCurrentIdEvaluation(idEvaluation);
    setContextCurrentIdQuestionnaire(idQuestionnaire);
    setContextQuestionnaireTimeLeft(timeLeft);
    setContextGoToEvaluationScreen(setEvaluationKnowledgeCurrentComponent);
    setEvaluationKnowledgeCurrentComponent(getInitialComponent());
    setContextRegistration(true);
  }, []);

  const getInitialComponent = () => {
    let initialComponent: string = "";

    if (finishDate) {
      initialComponent = "FEEDBACK";
    } else if (evaluationInitializedCurrentIndex(answers) == false) {
      initialComponent = "INTRODUCTION";
    } else {
      initialComponent = "PLAYER";
    }

    return initialComponent;
  };

  const currentPrintComponent = () => {
    switch (evaluationKnowledgeCurrentComponent) {
      case "INTRODUCTION":
        return <EvaluationKnowledgeIntroduction />;

        break;

      case "PLAYER":
        return <EvaluationKnowPlayer finishEvent={finishEvent} />;

        break;

      case "FEEDBACK":
        return <EvaluationKnowledgeFeedback />;

        break;

      default:
        return <p>Componente não encontrado.</p>;

        break;
    }
  };

  if (!contextRegistration) return <Preloader />;

  return currentPrintComponent();
};

export default EvaluationKnowledge;
