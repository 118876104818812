import "./style.scss";
import React, { useState, useRef, Fragment } from "react";
import { LabelBreadCramb } from "pctComponents/molecules";
import useMediaQuery from "pctHooks/useMediaQuery";

type Props = {
  collection: BlockElement | any;
  className?: string;
};

const BreadCrambItem = (
  lastCramb,
  dataParent,
  _collection,
  setbcOpen = null
) => {
  return (
    <div className={`cramb ${lastCramb ? "blockClick" : ""}`}>
      <LabelBreadCramb
        dataParent={dataParent}
        disabled={lastCramb ? true : false}
        collection={_collection}
        setbcOpen={setbcOpen}
      />
      {!lastCramb && <b className="bullet">• </b>}
    </div>
  );
};

const BreadCrambDesktop = (currentParents) => {
  const rows = currentParents.map((_collection: any, i) => {
    const dataParent = i > 0 ? currentParents[i - 1] : null; /// pai, se for o primeiro e null
    const lastCramb = i === currentParents.length - 1;

    return (
      <Fragment key={i}>
        {BreadCrambItem(lastCramb, dataParent, _collection)}
      </Fragment>
    );
  });

  return rows;
};

const BreadCrambMobile = (currentParents, bcOpen, setbcOpen) => {
  const firstElement = BreadCrambItem(
    false,
    null,
    currentParents[0],
    setbcOpen
  );
  const lastElement = BreadCrambItem(
    true,
    currentParents[currentParents.length - 1],
    currentParents[currentParents.length - 1],
    setbcOpen
  );
  return (
    <>
      {firstElement}
      <div className="breadCrambMiddle">
        <div
          className={`breadCrambMiddleHit ${bcOpen ? "bCbMHitActive" : ""}`}
          onClick={() => {
            setbcOpen(!bcOpen);
          }}
        ></div>
        <button
          className="breadCrambMiddleBtn"
          onClick={() => {
            setbcOpen(!bcOpen);
          }}
        >
          <b className="bulletMiddle">• • •</b>
        </button>
        <div
          className={`breadCrambMiddleContainer ${bcOpen ? "activeBC" : ""}`}
        >
          {currentParents
            .filter(
              (_collection: any, i) => i > 0 && i < currentParents.length - 1
            )
            .map((_collection: any, i) => {
              return (
                <Fragment key={i}>
                  {BreadCrambItem(
                    false,
                    currentParents[i - 1],
                    _collection,
                    setbcOpen
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
      {lastElement}
    </>
  );
};

const BreadCramb = ({ collection, className = "" }: Props) => {
  const [bcOpen, setbcOpen] = useState(false);
  const smStatus = useMediaQuery("sm");

  if (!collection) return <></>;
  if (!collection.node) return <></>;
  if (!collection.node.parentNodes) return <></>;

  let currentParents = collection.node.parentNodes;

  if (currentParents.indexOf(collection.node) == -1) {
    currentParents.push(collection.node);
  }

  ///verifica se tem mais de 3 itens e se esta na versao mobile
  const BreadCrambStatusView = currentParents.length > 3 && smStatus;

  const rows = BreadCrambStatusView
    ? BreadCrambMobile(currentParents, bcOpen, setbcOpen)
    : BreadCrambDesktop(currentParents);

  return (
    <div
      className={`pctBreadCramb ${className} ${
        BreadCrambStatusView ? "breadCrambMobile" : ""
      }`}
    >
      {rows}
    </div>
  );
};

export default BreadCramb;
