import "./style.scss";
import React from "react";
import { Image, Label, ProgressBar, Text, BrurEffect } from "../../atoms";
import { MenuTree } from "../../molecules";

type Props = {
  collection: any
  treeType?: string
};

const TreeList = ({ collection, treeType }: Props) => {
  
  
  const data = collection;
  const { image, description, label, nodeCode, title, completed, scormStatus, progress, scormProcessStatus } = collection;
  let concluidedText = scormStatus;
  
  if (scormStatus == "COMPLETED") {
    concluidedText = "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
  } else if (scormStatus == "NOT_ATTEMPTED") {
    concluidedText = "Não iniciado";
  } else if (scormStatus == "IN_PROGRESS") {
    concluidedText = "Em andamento";
  } else if (scormStatus == "INCOMPLETE") {
    concluidedText = "Em andamento";
  } else if (scormStatus == "PASSED") {
    concluidedText = "<i class='fa fa-check' aria-hidden='true'></i> Aprovado";
  } else if (scormStatus == "FAILED") {
    concluidedText = "<i class='fa fa-xmark'></i> Reprovado";
  }


  return (
    <div className="nodeTreeList">
      <div className="treeCover">
        {image && (
          <div className="coverImage">
            <Image path={image} />
          </div>
        )}

        {label && <Label text={label} />}

        {title && <Text component={"h3"} text={title} />}

        {description && <Text component={"p"} text={description} />}
        

        <ProgressBar percentage={progress}/>
        <Text component={"small"} text={concluidedText} className="statusLabel"/>
        

        
      </div>

      <div className="treeListContent">
        {title && <Text component={"h4"} text={ title } />}
         
        {scormProcessStatus === "PENDING" && <BrurEffect text={`Aguarde, o pacote Scorm "${title}" está sendo processado! Isto pode levar alguns minutos...`}/>}
        
        <MenuTree collection={collection} treeType={ treeType?treeType:"node" }/>
      </div>
    </div>
  );
};

export default TreeList;