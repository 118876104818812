import './style.scss';

import React, { Component, useState } from 'react';
import { Link, Redirect, NavLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ReactSVG } from 'react-svg';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { GrConfigure } from 'react-icons/gr';

import { TbMenu2, TbSearch } from 'react-icons/tb';
import { RxAvatar } from 'react-icons/rx';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { IoHomeOutline } from 'react-icons/io5';
import { FiLogOut, FiAlignJustify, FiUser } from 'react-icons/fi';
import { BiHomeAlt } from 'react-icons/bi';
import { Collapse } from 'react-collapse';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import ModalOnboard from '../../modals/ModalOnboard';

import homeIco from '../../../assets/images/home_02.svg';
import profile from '../../../assets/images/profile.svg';
import jornada from '../../../assets/images/jornada.svg';
import historico from '../../../assets/images/historico.svg';
import certificado from '../../../assets/images/certificado.svg';
import gameficacao from '../../../assets/images/game.svg';
import tutorial from '../../../assets/images/tutorial.svg';
import faleConosco from '../../../assets/images/faleConosco.svg';
import adm from '../../../assets/images/adm.svg';
import request from '../../../assets/images/request.svg';

import CloseIcon from '../../../assets/images/close-icon-menu.svg';
import UserIcon from '../../../assets/images/no-gender-user.svg';

import SearchIcon from '../../../assets/images/search.svg';
import LogoutIcon from '../../../assets/images/logout.svg';
import HomeIcon from '../../../assets/images/home.svg';
import BellIcon from '../../../assets/images/bell.svg';
import ArrowRight from '../../../assets/images/arrowRight.svg';
import GearIcon from '../../../assets/images/gear.svg';
import ChatIcon from '../../../assets/images/chat.svg';

import { getMenuData, logout, getToken, listFilter, getEmail } from '../../../services/api'; // TODO - getMenuData não utilizado
import { getRoute, getIcon, transformText, getColors, getSectionByType, getProfileInfo, getAppDetails } from '../../../services/utils'; // TODO - getRoute, getIcon não utilizado

import MenuFind from '../../../components/MenuFind';
import ChatRoom from '../../../components/mini-chat/ChatRoom';
import ChatRoomsList from '../../../components/mini-chat/ChatRoomsList';
import { PointsFooter } from '../../../components/Points';
import AdminAccess from '../../../components/AdminAccess';
import RedirectAccess from '../../../components/RedirectAccess';
import { MenuInProgress, MenuProducts } from 'pctComponents/molecules';
import { cloneModalAccessSupport } from 'pctComponents/modals/ModalSupport';
import { exitSupportAccess } from 'pctComponents/molecules/SupportAccess';

class MenuPrincipal extends Component {
    constructor(props) {
        super(props);

        //console.log(props);

        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const platform = window.navigator.platform;

        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const isIOS = iosPlatforms.indexOf(platform) !== -1;

        this.state = {
            isDesktop: false,
            user: {},
            contentData: [],
            page: 1,
            totalPages: 0,
            searchTags: '',
            isSearching: false,
            wordRequired: false,
            secondaryExpanded: false,
            needPressEnter: true,
            displayChat: isChrome && isIOS ? false : true,
            hideFooter: false,
            currentroom: null,
            showModal: false,
            showRedirect: false,
            sectionTo: null,
            isContactUs: false,
            isTitleProducts: 'Conteúdos',
            loadingAccessSupport: false,
        };

        this.resize = this.resize.bind(this);
        this.dataBlock = props.dataBlock;
    }

    resetMenu() {
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const platform = window.navigator.platform;

        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const isIOS = iosPlatforms.indexOf(platform) !== -1;

        this.setState({
            isDesktop: false,
            user: {},
            contentData: [],
            page: 1,
            totalPages: 0,
            searchTags: '',
            isSearching: false,
            wordRequired: false,
            secondaryExpanded: false,
            needPressEnter: true,
            displayChat: isChrome && isIOS ? false : true,
            hideFooter: false,
            currentroom: null,
            showModal: false,
            showRedirect: false,
            sectionTo: null,
            isContactUs: false,
            isTitleProducts: 'Conteúdos',
            loadingAccessSupport: false,
        });
    }

    componentDidMount() {
        this.resize();
        this.setState({
            user: this.getProfileInfo(),
        });
        this.setState({ productsIsButtonCollapseOpen: false });
        this.setState({ inProgressIsButtonCollapseOpen: false });

        window.addEventListener('resize', this.resize);

        getEmail('CONTACT_US')
            .then((response) => {
                this.setState({ isContactUs: !!response.data.active });
            })
            .catch((error) => {
                // console.log(error);
            });

        const getAppDetailsBucket = getAppDetails().bucket;
        const bucketStatus =
            getAppDetailsBucket === 'learninghub.peoplecraft.com.br' || getAppDetailsBucket === 'educacao.totvs.com'
                ? 'Produtos e Trilhas'
                : 'Conteúdos';

        this.setState({ isTitleProducts: bucketStatus });
    }

    getProfileInfo() {
        let token = {};
        try {
            token = jwtDecode(getToken());
        } catch {
            console.log('error on token get');
        }
        return token;
    }

    focusInput(shouldFocus) {
        if (this.nameInput) {
            if (shouldFocus) {
                this.nameInput.focus();
                this.setState({ wordRequired: true, isSearching: true });
            } else {
                this.setState({
                    wordRequired: false,
                    isSearching: false,
                    searchTags: '',
                    contentData: [],
                });
            }
        }
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth > 900 });
    }

    logout() {
        logout();
        this.setState({ redirect: true });
    }

    findContentState(word) {
        if (word.length === 0) {
            this.setState({ isLoading: false, searchTags: word, isSearching: false });
            return;
        }
        if (word.length === 1 || word.length === 2) {
            this.setState({
                isLoading: false,
                searchTags: word,
                isSearching: true,
                wordRequired: true,
                contentData: [],
            });
            return;
        }
        this.setState({ searchTags: word, wordRequired: false, page: 1 }, () => {
            this.findContent();
        });
    }

    findContent() {
        if (this.state.searchTags.length === 0) {
            return;
        }
        if (this.state.searchTags.length === 1 || this.state.searchTags.length === 2) {
            return;
        }

        this.setState({
            isLoading: true,
            isSearching: true,
            wordRequired: false,
            page: 1,
            needPressEnter: false,
        });
        listFilter(0, { page: 1, searchTags: this.state.searchTags })
            .then((res) => {
                if (!res || res.data.success === false) {
                    this.setState({ isLoading: false, contentData: [], totalPages: 0 });
                    return;
                }
                if (res.data.success === false) {
                    this.setState({ isLoading: false });
                    return;
                }
                this.setState({
                    isLoading: false,
                    contentData: res.data.content,
                    totalPages: Math.ceil(res.data.totalRecord / res.data.content.length), // divide by number of total items by page
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                console.log('error ', err);
            });
    }

    hideFooter(value) {
        this.setState({ hideFooter: value });
    }

    searchByPage(page) {
        const data = {
            page: page,
            searchTags: this.state.searchTags,
        };

        this.setState({ isLoading: true, page: page });

        listFilter(0, data)
            .then((res) => {
                if (!res || res.data.success === false) {
                    this.setState({ isLoading: false, data: [], totalPages: 0 });
                    return;
                }
                this.setState({
                    isLoading: false,
                    contentData: res.data.content,
                    totalPages: Math.ceil(res.data.totalRecord / res.data.content.length),
                });
            })
            .catch((err) => {
                console.log('err ', err);
                this.setState({ isLoading: false });
            });
    }

    openChat() {
        this.hideFooter(true);
        this.setState({ secondaryExpanded: true });
    }

    closeChat() {
        this.setState({ secondaryExpanded: false });
        this.hideFooter(false);
    }

    leaveChat() {
        this.setState({ currentroom: null });
        this.hideFooter(false);
    }

    handleChangeRoom(r) {
        this.setState({
            currentroom: r,
            hideFooter: true,
        });
    }

    _getMenuData() {
        return getSectionByType(1);
    }

    showModal() {
        NiceModal.show(cloneModalAccessSupport(), { adminProps: {} });
    }

    async closeSupportAccess() {
        await exitSupportAccess({
            setLoadingAccessSupport: (status) => { 
                this.setState({ loadingAccessSupport: status });
                this.resetMenu();
                this.props.expand();
            },
        });
    }

    openAccessSupportModal() {
        this.showModal();
        this.props.expand();
    }

    render() {
        if (this.state.loadingAccessSupport) {
            return <Redirect to="/" />;
        }

        if (this.state.redirect) {
            return <Redirect to="/login" />;
        }
        const { isDesktop } = this.state; // TODO - item não utilizado
        // const menuData = this._getMenuData()?.menuOption;
        const menuData = localStorage.getItem('menu-data') ? JSON.parse(localStorage.getItem('menu-data')) : null;
        const appDetails = JSON.parse(localStorage.getItem('appDetails'));

        const getAdmLink = () => {
            let linkAdm = '';

            if (!menuData) {
                linkAdm = `https://${appDetails.adminDomain}`;
            } else {
                linkAdm = `https://${appDetails.adminDomain}?token=${menuData.token}#/login`;
            }

            return linkAdm;
        };

        const userIsAdmin = () => {
            let isAdmin = false;

            if (menuData) {
                if (menuData.loginInfo.admin === 1 || menuData.loginInfo.admin === 2) {
                    isAdmin = true;
                }
            }

            return isAdmin;
        };

        const userCanAccessSupport = () => {
            return Boolean(menuData?.loginInfo?.canAccessSupport);
        };

        const { onBoardScreen } = menuData.loginInfo;
        const photo = this.state.user.photo || UserIcon;
        const handleCollapseProducts = (e) => {
            this.setState({
                productsIsButtonCollapseOpen: this.state.productsIsButtonCollapseOpen ? false : true,
            });
        };

        const handleCollapseInProgerss = (e) => {
            this.setState({
                inProgressIsButtonCollapseOpen: this.state.inProgressIsButtonCollapseOpen ? false : true,
            });
        };

        const inAccessSupport = Boolean(menuData?.loginInfo?.accessSupport?.isActive);

        return (
            <>
                <div
                    className={'menu-expand ' + (this.props.expanded ? 'menu-expand-open' : '')}
                    style={{
                        overflowY: this.state.isSearching ? 'auto' : 'hidden',
                    }}
                >
                    <div className="menu-hit" onClick={() => this.props.expand()}></div>
                    {/* <div className="menu-close-container">
          <div className="menu-close" onClick={() => {
            this.setState({ wordRequired: false, isSearching: false })
            this.props.expand()
            }}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute(
                  "style",
                  `width: 20px; height: 20px; margin-bottom: 5px; fill: #fff`
                );
              }}
              src={CloseIcon}
              alt="img"
            />
          </div>
          <div className={"menu-search-button menu-search-button-expand"}>
            <img
              src={SearchIcon}
              style={{
                height: 20,
                width: 20,
                float: "left",
                marginLeft: 3,
                marginTop: 3,
              }}
              alt="img"
            />
            <input
              spellCheck="false"
              className="menu-search-input"
              placeholder="Procurar conteúdo"
              ref={(input) => {
                this.nameInput = input;
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.findContent();
                }
              }}
              onChange={(c) => {
                this.findContentState(c.target.value);
              }}
              value={this.state.searchTags}
              maxLength={25}
              type="search"
            />
            {this.state.isSearching && (
              <div
                className="menu-search-cancel"
                onClick={() => {
                  this.setState({ searchTags: "", isSearching: false });
                }}>
                &#10005;
              </div>
            )}
          </div>
        </div> */}
                    <div className="menu-secondary-container">
                        {/* <div className="menu-close" onClick={() => {
            this.setState({ wordRequired: false, isSearching: false })
            this.props.expand()
            }}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute(
                  "style",
                  `width: 20px; height: 20px; margin-bottom: 5px; fill: #fff`
                );
              }}
              src={CloseIcon}
              alt="img"
            />
          </div> */}

                        <div style={{ display: 'flex', flex: 1 }}>
                            <div className="menu-secondary-left">
                                {/* CLOSE BTN */}
                                <ReactSVG
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item menu-secondary-left-item-closed"
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('style', `width: 20px; height: 20px; margin-bottom: 5px; fill: #fff`);
                                    }}
                                    src={CloseIcon}
                                    alt="fechar menu"
                                />
                                {/* HOME */}
                                <NavLink
                                    to=""
                                    exact
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item"
                                    // activeClassName="left-item-selected"
                                >
                                    <ReactSVG src={homeIco} alt="img" className="navLinkImg" />
                                    <div className="navLinkTxt">Home</div>
                                </NavLink>
                                {/* MINHA JORNADA */}
                                <NavLink
                                    to="/myjourney"
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item"
                                    //activeClassName="left-item-selected"
                                >
                                    <ReactSVG src={jornada} alt="img" className="navLinkImg" />
                                    <div className="navLinkTxt">Minha jornada</div>
                                </NavLink>
                                {/* Solicitações de matrícula */}
                                {this.props.hasEnrollmentRequests && (
                                    <NavLink
                                        to="/myjourney/requestEnrollmentStatus"
                                        onClick={() => this.props.expand()}
                                        className="menu-secondary-left-item"
                                        //activeClassName="left-item-selected"
                                    >
                                        <ReactSVG src={request} alt="img" className="navLinkImg" />
                                        <div className="navLinkTxt">Solicitações de matrícula</div>
                                    </NavLink>
                                )}
                                {/* Meu Historico */}
                                <NavLink
                                    to="/myjourney/historic"
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item"
                                    //activeClassName="left-item-selected"
                                >
                                    <ReactSVG src={historico} alt="img" className="navLinkImg" />
                                    <div className="navLinkTxt">Meu histórico</div>
                                </NavLink>
                                {/* Meu Certificados */}
                                <NavLink
                                    to="/myjourney/certificates"
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item"
                                    //activeClassName="left-item-selected"
                                >
                                    <ReactSVG src={certificado} alt="img" className="navLinkImg" />
                                    <div className="navLinkTxt">Meus certificados</div>
                                </NavLink>
                                {this.props.isInCampaign && (
                                    <NavLink
                                        to="/myjourney/gamification"
                                        onClick={() => this.props.expand()}
                                        className="menu-secondary-left-item"
                                        //activeClassName="left-item-selected"
                                    >
                                        <ReactSVG src={gameficacao} alt="img" className="navLinkImg" />
                                        <div className="navLinkTxt">Campanhas e Gamificação</div>
                                    </NavLink>
                                )}
                                {/* NOTIFICAÇÃO */}
                                {/* <NavLink to="/messages" onClick={() => this.props.expand()} className="menu-secondary-left-item" activeClassName="left-item-selected">
                                            <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute('style', `width: 25px; height: 25px; fill: ${getColors('menuItem')?.color || 'white'}`)
                                                }}
                                                src={BellIcon}
                                                alt="notif"
                                            />
                                        </NavLink> */}
                                {/* PROFILE */}
                                <NavLink
                                    to="/editPerfil"
                                    onClick={() => this.props.expand()}
                                    className="menu-secondary-left-item"
                                    // activeClassName="left-item-selected"
                                >
                                    {/* <FiUser className="IcoMenu"/> */}
                                    <ReactSVG src={profile} alt="img" className="navLinkImg" />
                                    <div className="navLinkTxt">Meu perfil</div>
                                </NavLink>
                                {/* TUTORIAL */}
                                {onBoardScreen && (
                                    <div
                                        onClick={() => {
                                            NiceModal.show(ModalOnboard, {
                                                obj: {
                                                    // closedModal:(e) => {
                                                    //
                                                    // }
                                                },
                                            });
                                            this.props.expand();
                                        }}
                                        className="menu-secondary-left-item"
                                        //activeClassName="left-item-selected"
                                    >
                                        <ReactSVG src={tutorial} alt="img" className="navLinkImg" />
                                        <div className="navLinkTxt">Tutorial</div>
                                    </div>
                                )}
                                {/* FALE CONOSCO */}
                                {this.state.isContactUs && (
                                    <NavLink
                                        to="/contactus"
                                        onClick={() => this.props.expand()}
                                        className="menu-secondary-left-item"
                                        //activeClassName="left-item-selected"
                                    >
                                        <ReactSVG src={faleConosco} alt="img" className="navLinkImg" />
                                        <div className="navLinkTxt">Fale conosco</div>
                                    </NavLink>
                                )}
                                {/*  ADM */}
                                {userIsAdmin() && (
                                    <>
                                        {!inAccessSupport && (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={getAdmLink()}
                                                onClick={() => this.props.expand()}
                                                className="menu-secondary-left-item"
                                            >
                                                <ReactSVG src={adm} alt="img" className="navLinkImg" />
                                                <div className="navLinkTxt">Administração</div>
                                            </a>
                                        )}

                                        {/* Acesso Suporte */}
                                        {userCanAccessSupport() && !inAccessSupport && (
                                            <button onClick={() => this.openAccessSupportModal()} className="menu-secondary-left-item support-button">
                                                <div className="navLinkImg">
                                                    <div>
                                                        <GrConfigure alt="img" className="injected-svg menu-icon" />
                                                    </div>
                                                </div>
                                                <div className="navLinkTxt">Acesso Suporte</div>
                                            </button>
                                        )}
                                    </>
                                )}
                                {inAccessSupport && (
                                    <button onClick={() => this.closeSupportAccess()} className="menu-secondary-left-item support-button">
                                        <div className="navLinkImg">
                                            <div>
                                                <GrConfigure alt="img" className="injected-svg menu-icon" />
                                            </div>
                                        </div>
                                        <div className="navLinkTxt">Sair do acesso suporte</div>
                                    </button>
                                )}

                                {/* CHAT */}
                                {/* <div
                  onClick={() => this.openChat()}
                  className="menu-secondary-left-item">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        "style",
                        `width: 25px; height: 25px; fill: #fff`
                      );
                    }}
                    src={ChatIcon}
                    alt="chat"
                  />
                </div> */}
                                {/* LOJINHA */}
                                {/* {appDetails?.store === 1 && (
                  <NavLink
                    to="/lojinha"
                    onClick={() => this.props.expand()}
                    className="menu-secondary-left-item"
                    activeClassName="left-item-selected">
                    <ShoppingCartIcon
                      style={{
                        width: 25,
                        height: 25,
                        color: "#fff",
                      }}
                    />
                  </NavLink>
                )} */}
                            </div>
                            <div className="menu-secondary-right">
                                <div className="menu-item">
                                    <button
                                        className={`${this.state.productsIsButtonCollapseOpen ? 'menuItemIsOpen' : 'menuItemIsClose'} menu-text-btn`}
                                        onClick={(e) => {
                                            handleCollapseProducts(e);
                                        }}
                                    >
                                        <span className="menu-text"> {this.state.isTitleProducts} </span>
                                        {this.state.productsIsButtonCollapseOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </button>

                                    <Collapse isOpened={this.state.productsIsButtonCollapseOpen}>
                                        <MenuProducts closeRoot={this.props.expand} />
                                    </Collapse>
                                </div>

                                <div className="menu-item">
                                    {/* <span className="menu-text"> Continue de onde parou </span> */}
                                    <button
                                        className={`${
                                            this.state.inProgressIsButtonCollapseOpen ? 'menuItemIsOpen' : 'menuItemIsClose'
                                        } menu-text-btn`}
                                        onClick={(e) => {
                                            handleCollapseInProgerss(e);
                                        }}
                                    >
                                        <span className="menu-text"> Continue de onde parou </span>
                                        {this.state.inProgressIsButtonCollapseOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </button>

                                    <Collapse isOpened={this.state.inProgressIsButtonCollapseOpen}>
                                        <MenuInProgress closeRoot={this.props.expand} />
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showModal && (
                        <AdminAccess
                            denyAction={() => this.setState({ ...this.state, showModal: false })}
                            acceptAction={() => this.setState({ ...this.state, showModal: false })}
                        />
                    )}
                    {this.state.showRedirect && (
                        <RedirectAccess
                            idSection={this.state.sectionTo}
                            denyAction={() => this.setState({ ...this.state, showRedirect: false })}
                            acceptAction={() => this.setState({ ...this.state, showRedirect: false })}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default MenuPrincipal;
