import React, { useEffect, useCallback, useState } from "react";

import { Text } from "pctComponents/atoms"; 

import BreadCramb from "../../BreadCramb";

import { IoMdClose } from "react-icons/io";
import { GrFormPrevious } from "react-icons/gr";

import useBackControll from "pctHooks/useBackControll";
import usePlan from "pctHooks/usePlan";

import { MenuHeaderContent } from "pctComponents/molecules";
import InteractionDrawer from "pctComponents/organisms/InteractionDrawer";

function HeadeNavControlls({
  prevNavStyle,
  closeFn,
  navHistory,
  backCloseText,
  nodeCollection, 
  updatePlan,
}) {
  const { backControllFn } = useBackControll({});
  const { getData } = usePlan();

  const setInitialStatePlan = useCallback(async () => {
    const data = await getData();
    updatePlan(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlan]);

    
  useEffect(() => {
    setInitialStatePlan();
    return () => {};
  }, [setInitialStatePlan]);

  return (
    <div className="navControlls">
      <button
        className={`prevNavHeader ${prevNavStyle}`}
        onClick={() => {
          if (closeFn) {
            closeFn();
          } else {
            backControllFn(navHistory);
          } 
        }}
      >
        <div className="prevNavBase prevNavIco">
          <GrFormPrevious />
          <Text
            component="small"
            text={
              backCloseText || backCloseText === "" ? backCloseText : "VOLTAR"
            }
            className="prevTxt"
          />
        </div>

        <div className="prevNavBase closeNavIco">
          <IoMdClose />
          <Text
            component="small"
            text={
              backCloseText || backCloseText === "" ? backCloseText : "FECHAR"
            }
            className="prevTxt"
          />
        </div>
      </button>

      <div className="headerBreadMenuContent">
        <BreadCramb collection={nodeCollection} />
        <MenuHeaderContent nodeCollection={nodeCollection} />
      </div>
    </div>
  );
}

export default HeadeNavControlls;
