import './styles.scss';
import React from 'react';
import { Notification, Button } from 'pctComponents/atoms';
import { getNumberRequest, postRequestTrial } from 'services/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MdRocketLaunch } from 'react-icons/md';
import ModalLoadingInfo from 'pctComponents/modals/ModalLoadingInfo';
import { OriginNavigation } from 'pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model';
import usePlan from 'pctHooks/usePlan';

type Props = {
    text?: string;
    hide?: boolean;
    css?: object;
    className?: string;
    numberRequest: number;
    hideNotification: boolean;
    userIsAdmin: boolean;
    hasRequestedUpgrade?: boolean;
    originNavigation: OriginNavigation;
    disableRuleIconModal?: boolean;
    callEvent?: React.MouseEventHandler<HTMLButtonElement> | null;
    isButtonActive?: Boolean;
    name?: string;
    idPlan: number;
    idCompanyApp: number;
    startDate: string;
    finishDate: string;
    active: boolean;
    deadline: number;
    planUpgrade?: any;
    updatePlan?: any;
    trial?: boolean;
    forceVisible?: boolean;
};

const PctPlanContract = ({
    text,
    hide = false,
    hideNotification = false,
    originNavigation = OriginNavigation.header,
    disableRuleIconModal = false,
    name,
    idPlan,
    idCompanyApp,
    startDate,
    userIsAdmin,
    finishDate,
    active,
    deadline,
    callEvent,
    planUpgrade,
    updatePlan,
    trial,
    forceVisible = false,
    ...props
}: Props) => {
    const { openModalUpgrade } = usePlan();
    const modalLoading = useModal('ModalLoadingInfo');
    NiceModal.register('ModalLoadingInfo', ModalLoadingInfo);

    const getNumberNotification = async () => {
        const {
            data: { likeCount },
        } = await getNumberRequest(idPlan);

        updatePlan({ hasRequestedUpgrade: true, numberRequest: likeCount });
    };

    const openModal = () => {
        openModalUpgrade({ ...planUpgrade, userIsAdmin });
    };

    const requestUpgrade = async () => {
        try {
            if (planUpgrade.hasRequestedUpgrade) {
                getNumberNotification();
                openModal();
                return;
            }
            modalLoading.show({
                obj: {
                    loadding: true,
                    closeBtn: false,
                },
            });
            await postRequestTrial({ idPlan });
            openModal();
            getNumberNotification();
        } catch (e) {
            console.log(e);
        } finally {
            modalLoading.remove();
        }
    };

    const handleClick = (e) => {
        if (callEvent) {
            callEvent(e);
        }

        if (!userIsAdmin) {
            requestUpgrade();
            return;
        } else {
            getNumberNotification();
        }

        document.body.classList.add('bodyOverflowHidden');
        openModal();
    };

    return (
        <>
            { (forceVisible || (!!trial && !hide && deadline > 0)) && (
                <div className="pctUpgrade">
                    {!hideNotification && !!planUpgrade.numberRequest && (
                        <Notification text={`${planUpgrade.numberRequest}`} hasBell={false} className="notification" />
                    )}
                    <Button
                        callEvent={(e) => handleClick(e)}
                        className={`button07 buttonDefault  ${!disableRuleIconModal && 'buttonIconText'}`}
                        ico={MdRocketLaunch}
                        text={
                            text ||
                            (userIsAdmin ?
                                    `${deadline === 1 ? 'Resta' : 'Restam'} <span>${deadline} ${deadline === 1 ? 'dia' : 'dias'}`
                                : 'Diga que está gostando do trial')
                        }
                    />
                </div>
            )}
        </>
    );
};

export default PctPlanContract;
