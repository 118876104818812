import "./style.scss";

import React from "react";

import { IconType } from "react-icons";
import { Text, Icon } from "../";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
type Props = {
  className?: string;
  css?: {};
  text?: string;
  link?: string | null;
  callEvent?: React.MouseEventHandler<HTMLButtonElement> | null;
  ico?: IconType | any;
  svg?: string | null | undefined;
  attr?: object;
  disabled?: boolean;
};

const Button = (props: Props) => {
  const history = useHistory();

  const goPath = (path) => {
    if (path !== "") {
      history.push(path);
    }
  };

  const {
    ico,
    svg,
    className = "",
    text,
    link = null,
    callEvent = null,
    css = {},
    attr = {},
    disabled = false
  } = props;

  return (
    <button
      className={`pctAtom pctButton ${className}`}
      style={css}
      disabled={disabled}
      onClick={(e) => {
        callEvent ? callEvent(e) : goPath(link);
      }}
      {...attr}
    >
      {ico && <Icon icone={ico} className="icone" />}

      {svg && <span className="icone">{parse(`${svg}`)}</span>}

      {text && <Text component="div" text={`${text}`} />}
    </button>
  );
};

export default Button;
