import "./style.scss";

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProfileInfo } from "services/utils";
import { useHistory } from "react-router-dom";
import { updateConfigHeaderFooter } from "../../../actions";
import { Text, Avatar } from "../../atoms";
import {
  MyCertificates,
  MyHistoric,
  ListGamification,
  RequestEnrollmentStatus,
} from "pctComponents/organisms";
import { MenuTree } from "pctComponents/molecules";

import { getEnrollmentCampaignStatus } from "services/utils";

const MyJourney = ({
  updateConfigHeaderFooter,
  match,
  configHasEnrollmentCampaign,
}) => {
  const userProfile = getProfileInfo();
  const [currentView, setCurrentView] = useState(
    match.params.tab ? match.params.tab : "registered"
  );

  const [hasEnrollmentRequests, setHasEnrollmentRequests] = useState(false);
  const [isInCampaign, setIsInCampaign] = useState(false);

  const handleMenuMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const elem = e.target as HTMLInputElement;
    setCurrentView(elem.id);
    var itemTarget: any = e.target;

    switch (itemTarget.id) {
      case "registered":
        setCurrentView(itemTarget.id);
        break;

      case "requestEnrollmentStatus":
        setCurrentView(itemTarget.id);
        break;

      case "historic":
        setCurrentView(itemTarget.id);
        break;

      case "certificates":
        setCurrentView(itemTarget.id);
        break;
    }
  };

  const history = useHistory();

  const redictToPerfil = () => {
    history.push(`/editPerfil`);
  };

  useEffect(() => {
    updateConfigHeaderFooter({
      prevNavStyle: "prevShow closeShow",
      backCloseText: "FECHAR",
    });
  }, []);

  // useEffect(() => {
  //   // console.log(configHasEnrollmentCampaign);
  //   const { tab } = match.params;
  //   const { hasEnrollmentRequests, userIsInCampaign } =
  //     configHasEnrollmentCampaign;
  //   setHasEnrollmentRequests(hasEnrollmentRequests);
  //   setIsInCampaign(userIsInCampaign);

  //   // if(currentView === 'requestEnrollmentStatus' && configHasEnrollmentCampaign.hasEnrollmentRequests)
  // }, [configHasEnrollmentCampaign]);

  //Verifica no inicio do header se o status da hasEnrollmentRequests e userIsInCampaign estao ativos
  useEffect(() => {
    if(configHasEnrollmentCampaign){
      const {hasEnrollmentRequests, userIsInCampaign} = configHasEnrollmentCampaign
   

      const { tab } = match.params;

      if (tab === "requestEnrollmentStatus" && !hasEnrollmentRequests) {
        setCurrentView("registered");
      } else if (
        tab === "requestEnrollmentStatus" &&
        hasEnrollmentRequests
      ) {
        setCurrentView("requestEnrollmentStatus");
      } else if (tab === "gamification" && !userIsInCampaign) {
        setCurrentView("registered");
      } else if (tab === "gamification" && userIsInCampaign) {
        setCurrentView("gamification");
      }
    }
    
  }, [configHasEnrollmentCampaign]);

  // const statusTranslate = (currentStatusTranslate) => {
  //   const arrStatus = ["NOT_ATTEMPTED", "IN_PROGRESS", "COMPLETED", "PASSED", "FAILED", "EXPIRED"];
  //   const arrStatusTranslate = ["Não iniciado", "Em progresso", "Concluído", "Aprovado", "Reprovado", "Turma expirada"];
  //   const translateWordIndex = arrStatus.indexOf(currentStatusTranslate);
  //   let translatedStatus = "-";

  //   if(translateWordIndex){
  //     translatedStatus = arrStatusTranslate[translateWordIndex];
  //   }

  //   return translatedStatus;

  // };

  return (
    <div className="pctPage pctPageBlock pctJourney">
      <div className="sectionList">
        <div className="journeyHeader">
          <div className="journeyPerfil">
            <div
              className="avatar"
              onClick={() => {
                redictToPerfil();
              }}
            >
              <Avatar />
              <Text text={userProfile.name} className="nameUser" />
              <Text text="Editar perfil" className="edit" />
            </div>
          </div>
          <div className="journeyBannerImage"></div>
        </div>

        <div className="journeyBody">
          {/* { currentView == "registered"  && (
                        <div className='filtersContainer'>
                        <Text text="Refinar busca" className='label'/>
                        <FilterLabels arrFilterObjects={arrFilterObjects} setFilterObj={setFilterObj}/>
                    </div>
              )} */}

          <div className="contentsView">
            <div className="contentsViewMenu">
              <Text text="Minha Jornada" component={"h3"} className="label" />
              <div className="journeyNavBar">
                <ul>
                  <li
                    id="registered"
                    className={currentView === "registered" ? "active" : ""}
                    onClick={(e) => {
                      handleMenuMouseEvent(e);
                    }}
                  >
                    {" "}
                    Matriculados{" "}
                  </li>
                  {configHasEnrollmentCampaign.hasEnrollmentRequests && (
                    <li
                      id="requestEnrollmentStatus"
                      className={
                        currentView === "requestEnrollmentStatus"
                          ? "active"
                          : ""
                      }
                      onClick={(e) => {
                        handleMenuMouseEvent(e);
                      }}
                    >
                      Solicitações de matrícula
                    </li>
                  )}
                  <li
                    id="historic"
                    className={currentView === "historic" ? "active" : ""}
                    onClick={(e) => {
                      handleMenuMouseEvent(e);
                    }}
                  >
                    Meu histórico
                  </li>
                  <li
                    id="certificates"
                    className={currentView === "certificates" ? "active" : ""}
                    onClick={(e) => {
                      handleMenuMouseEvent(e);
                    }}
                  >
                    Meus certificados
                  </li>
                  {configHasEnrollmentCampaign.userIsInCampaign && (
                    <li
                      id="gamification"
                      className={currentView === "gamification" ? "active" : ""}
                      onClick={(e) => {
                        handleMenuMouseEvent(e);
                      }}
                    >
                      Campanhas e Gamificação
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="contentsShow">
              {currentView === "registered" && (
                // <MenuTree className='menuTree01' filterObj={filterObj}/>
                <MenuTree className="menuTree01" />
              )}

              {currentView === "requestEnrollmentStatus" && (
                <RequestEnrollmentStatus userId={userProfile.idUser} />
              )}

              {currentView === "historic" && (
                <MyHistoric userId={userProfile.idUser} />
              )}

              {currentView === "certificates" && (
                <MyCertificates userId={userProfile.idUser} />
              )}
              {currentView === "gamification" && (
                <ListGamification userId={userProfile.idUser} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

const mapStateToProps = (store) => ({
  configHasEnrollmentCampaign:
    store.hasEnrollmentCampaignState.configHasEnrollmentCampaign,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MyJourney);
