import { combineReducers } from 'redux';
import { footerReducer } from './footer';
import { contentReducer } from './content';
import { pointsReducer } from './points';
import { configHeaderFooterReducer } from './configHeaderFooter'; // PCT
import { coursesProgress } from './coursesProgress'; // PCT
import { configContentsMap } from './configContentsMap'; // PCT
import { configCampaignReducer } from './configCampaign'; // PCT
import { hasEnrollmentCampaignReducer } from './hasEnrollmentCampaign'; // PCT
import { planUpgrade } from './license'; // PCT
import { apiReplicationReducer } from './apiReplication'; // PCT

export const Reducers = combineReducers({
    footerState: footerReducer,
    contentState: contentReducer,
    pointsState: pointsReducer,
    configHeaderFooterState: configHeaderFooterReducer,
    coursesProgress: coursesProgress,
    configContentsMapState: configContentsMap,
    configCampaignState: configCampaignReducer,
    hasEnrollmentCampaignState: hasEnrollmentCampaignReducer,
    planUpgrade: planUpgrade,
    apiReplicationState: apiReplicationReducer,
});
