import "./style.scss";

import React, { useState, useEffect, useRef } from "react";

import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

import { Text } from "../";

enum direct {
  down = "DOWN",
  up = "UP",
  right = "RIGHT",
}

type Props = {
  model?: string;
  css?: object;
  className?: string;
  placeholder?: string;
  options: { title: string; [key: string]: any }[];
  onSelected: (value: {}) => void;
};

function DropDown({
  model = direct.down,
  placeholder = "Selecione",
  className = "",
  css = {},
  options,
  onSelected,
}: Props) {
  const dropRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const [widthDrop, setWidthDrop] = useState(100);
  const [rightList, setRightDrop] = useState(100);
  const [MTopList, setMTopList] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    title: string;
  } | null>(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelected(value);
  };

  useEffect(() => {
    if (dropRef.current && dropRef.current.clientWidth) {
      setWidthDrop(dropRef.current?.clientWidth);
    }

    if (dropRef.current && model === direct.right) {
      setRightDrop((dropRef.current?.clientWidth + 2) * -1);
    }

    if (listRef.current && model === direct.up) {
      setMTopList(listRef.current?.clientHeight * -1);
    }
  }, [dropRef, isOpen, listRef]);

  return (
    <div
      ref={dropRef}
      className={`DropDownContainer ${model} ${className}`}
      style={css}
      tabIndex={0}
      onBlur={() => {
        setIsOpen(false);
      }}
    >
      <div className={"DropDownHeader"} onClick={toggling}>
        <Text component="p" text={selectedOption?.title || placeholder} />
        {isOpen === true ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </div>
      {isOpen && (
        <div
          ref={listRef}
          className={"DropDownListContainer"}
          style={{
            width: widthDrop,
            marginTop: MTopList,
            right: model === direct.right ? rightList : "auto",
          }}
        >
          <ul className={"DropDownList"}>
            {options.map((option) => (
              <li
                className={"ListItem"}
                onClick={onOptionClicked(option)}
                key={Math.random()}
              >
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropDown;
