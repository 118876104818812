import React, { Fragment } from 'react';
import {
    MasterBanner,
    Carroussel,
    NodeLink,
    TextSection,
    TitleSection,
    EnrollmentsInProgress,
    Catalog,
    Grid,
    Agenda,
    Meeting,
    Recommendation,
    Upgrade,
} from '../pctComponents/organisms';
import AlertComponent from 'pctComponents/organisms/AlertComponent';
import Campaing from 'pctComponents/organisms/Campaing';

export const usePrintBlocks = (block: Block, key: number, _parentData?: any) => {
    block.elements = block.elements.filter(
        (x) =>
            (x.elementType === 'NODE' && x.data.idNode !== null) ||
            (x.elementType === 'CONTENT' && x.data.idContentType !== null) ||
            (x.elementType === 'EVALUATION' && x.idElement != null) ||
            (x.elementType === 'IMAGE' && x.idElement != null),
    );

    const { marginBottom, marginTop } = block.parameters;

    const sMBClass = marginBottom !== null ? 'section_mb section_mb_' + marginBottom : '';
    const sMTClass = marginTop !== null ? 'section_mt section_mt_' + marginTop : '';

    const sectionMClass = sMBClass + ' ' + sMTClass;
    const hasElements = (block: Block): boolean => !!block?.elements?.length;

    const isBlockTypeWithElements = (block: { blockType?: string }): boolean => {
        const blockTypesWithElements = ['Carrousel', 'Grid', 'List'];
        return blockTypesWithElements.includes(block.blockType || '');
    };

    if (isBlockTypeWithElements(block) && !hasElements(block)) {
        return null;
    }
  
    switch (block.blockType) {
        case 'MasterBanner': {
            return <MasterBanner key={key} data={block} _parentData={_parentData} />;
        }

        case 'Carrousel': {
            return (
                <section key={key} className={`sectionList sectionCarroussel  ${sectionMClass}`}>
                    <Carroussel data={block} _parentData={_parentData} />
                </section>
            );
        }

        case 'Recommendation': {
            return (
                <section key={key} className={`sectionList sectionRecommendation  ${sectionMClass}`}>
                    <Recommendation data={block} _parentData={_parentData} />
                </section>
            );
        }

        case 'EnrollmentsInProgress': {
            return (
                <section key={key} className={`sectionList sectionEnrollmentsInProgress  ${sectionMClass}`}>
                    <EnrollmentsInProgress data={block} _parentData={_parentData} />
                </section>
            );
        }

        case 'Catalog': {
            return <Catalog key={key} data={block} sectionMClass={sectionMClass} />;
        }

        case 'Grid': {
            return (
                <section key={key} className={`sectionList gridList  ${sectionMClass}`}>
                    <Grid data={block} _parentData={_parentData} />
                </section>
            );
        }

        case 'NodeLink': {
            return (
                <Fragment key={key}>
                    <NodeLink data={block} className={`sectionList sectionNodeLink  ${sectionMClass}`} />
                </Fragment>
            );
        }

        case 'Meeting': {
            return (
                <section key={key} className={`sectionList gridMeeting  ${sectionMClass}`}>
                    <Meeting data={block} _parentData={_parentData} />
                </section>
            );
        }

        case 'List': {
            return (
                <section key={key} className={`pctListContainer ${sectionMClass} ${block.parameters.banner === '1' ? 'pctMasterBannerList' : ''}`}>
                    {block.parameters.banner === '1' && <MasterBanner data={block} _parentData={_parentData} type="list" />}

                    <NodeLink data={block} _parentData={_parentData} className={`sectionList sectionNodeLink`} />
                </section>
            );
        }

        case 'ListBlocks': {
            return (
                <Fragment key={key}>
                    <NodeLink data={block} _parentData={_parentData} className={`sectionList sectionNodeLink  ${sectionMClass}`} />
                </Fragment>
            );
        }

        case 'TitleSection': {
            return (
                <Fragment key={key}>
                    {block.title ? <TitleSection className={`sectionList titleBlock  ${sectionMClass}`} html={block.title} /> : null}
                </Fragment>
            );
        }

        case 'TextSection': {
            return (
                <Fragment key={key}>
                    {block.text ? <TextSection className={`sectionList textBlock  ${sectionMClass}`} html={block.text} /> : null}
                </Fragment>
            );
        }

        case 'Upgrade': {
            return (
                <Fragment key={key}>
                    <Upgrade data={block} fullpage={false} className={` ${sectionMClass}`} />
                </Fragment>
            );
        }

        case 'Calendar': {
            return (
                <Fragment key={key}>
                    <Agenda data={block} className={` ${sectionMClass}`} />
                </Fragment>
            );
        }

        case 'Campaign': {
            return (
                <Fragment key={key}>
                    <Campaing data={block} className={` ${sectionMClass}`} />
                </Fragment>
            );
        }

        default: {
            return (
                <Fragment key={key}>
                    <AlertComponent
                        fullpage={false}
                        message={`Componente "${block.blockType ? block.blockType.toUpperCase() : 'N/A'}" não encontrado.`}
                    />
                </Fragment>
            );
        }
    }
};
