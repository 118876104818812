import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getProfileInfo } from './utils';

const environment = process.env.REACT_APP_ENVIRONMENT;

// User authentication
export const STORAGE_KEY = 'menu-data';
export const isAuthenticated = () => localStorage.getItem(STORAGE_KEY) !== null;
export const getToken = () => {
    let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (menuData) {
        return menuData.token;
    } else {
        return null;
    }
};

export const getMenuData = () => {
    let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return menuData;
};

export const getCacheData = (component) => {
    switch (component) {
        case 'YTPlaylist':
            return JSON.parse(localStorage.getItem('YTPlaylist'));
        case 'LiveList':
            return JSON.parse(localStorage.getItem('LiveList'));
        default:
            return false;
    }
};

export const setCacheData = (data, component) => {
    switch (component) {
        case 'YTPlaylist':
            return localStorage.setItem('YTPlaylist', JSON.stringify(data));
        case 'LiveList':
            return localStorage.setItem('LiveList', JSON.stringify(data));
        default:
            return false;
    }
};

export const setLoginData = (data) => {
    const date = moment().utc().format('YYYY-MM-DD');
    Object.assign(data.loginInfo, { lastLogin: date });
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const logout = () => {
    sessionStorage.removeItem('planModal');
    localStorage.removeItem('planModal');
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem('navigationHistory');
    removeStorageCampaing();
};

const removeStorageCampaing = () => {
    localStorage.removeItem('campaing');
};

export const api = axios.create({
    baseURL: environment,
    timeout: 30000,
});

api.interceptors.request.use(async (config) => {
    // Check internet connection
    if (!window.navigator.onLine) {
        // DOC: https://fkhadra.github.io/react-toastify/introduction/
        toast.error('Parece que você está sem conexão com a internet.', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    // Add token to request

    const token = getToken();

    if (token) {
        // This part of interception get the current header authorization otherwise set a new token of the current user
        config.headers.Authorization = config.headers?.Authorization || `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        // Redirect to login if token is expired
        const menuData = getMenuData();

        if (menuData) {
            const currentDate = moment().utc().format('YYYY-MM-DD');
            const lastLoginDate = menuData.loginInfo?.lastLogin;

            if (currentDate > lastLoginDate) {
                renewlogin();
            }
        }

        // Renew Token
        if (response.data.hasOwnProperty('newToken')) {
            updateToken(response.data.newToken);
        }

        return response;
    },
    (error) => {
        // Redirect on invalid token
        if (error?.response?.status === 401) {
            redirectInInvalidToken();
        }
    },
);

const redirectInInvalidToken = () => {
    localStorage.removeItem(STORAGE_KEY);
    sessionStorage.removeItem('planModal');
    localStorage.removeItem('planModal');
    window.location.replace('/');
};

// Renova o Login
export async function renewlogin(urlToken, extraLoginData = {}) {
    const token = urlToken || getToken();
    const profile = getProfileInfo(urlToken);
    const data = {
        idUser: profile.idUser,
        idAppType: profile.idAppType,
    };
    if (!token && !data.idUser) return; 
    const response = await api({
        method: 'POST',
        url: '/login/renewlogin',
        data: data,

        headers: {
            Authorization: `Bearer ${urlToken || getToken()}`,
        },
    });

    const lastAccess = response.data?.loginInfo?.lastAccess?.slice(0, 10);
    if (response.data.success === true) {
        setLoginData({
            ...response.data,
            loginInfo: { ...response.data.loginInfo, lastLogin: lastAccess, ...extraLoginData },
        });
    }

    return response;
}

export function loginSupportAccess(idUser) {
    return api({
        method: 'POST',
        url: '/login/support-access',
        data: { idUser },
    });
}

const updateToken = (newToken) => {
    let menuData = getMenuData();
    menuData.token = newToken;
    setLoginData(menuData);
};

// Lista de conteudo usando filtro
export function listFilter(id, data) {
    return api({
        method: 'POST',
        url: `/content/listfilter/${id}`,
        data: data,
    });
}

// Seleciona o appType de acordo com o endereco
export function appDetails(bucket) {
    return axios.post(`${environment}/app/details/`, { bucket: bucket });
}

// Autenticação do usuario
export function userLogin(login, password, appType, idApp, idCompany) {
    let payload = {
        username: `${login}`,
        password: `${password}`,
        idAppType: appType,
    };
    if (typeof idApp == 'number' && idApp !== 0) {
        payload.idApp = idApp;
    }
    if (typeof idCompany == 'number' && idCompany !== 0) {
        payload.idCompany = idCompany;
    }
    return api({
        method: 'POST',
        url: '/login',
        data: payload,
    });
}

export function loginSSO(bucket, idApp, idCompany) {
    const link = document.createElement('a');
    const callback = encodeURIComponent(`https://${bucket}/#/login`);

    link.href = `${environment}/login/sso/${idApp}`;

    if (idCompany) {
        link.href += `/${idCompany}`;
    }

    link.href += `?callback=${callback}`;

    link.click();
}

export function logoutSSO(url) {
    const link = document.createElement('a');
    link.href = url;
    link.click();
}

// Retorna o conteúdo do aluno
export function getContent(sectionId) {
    let appDetails = JSON.parse(localStorage.getItem('appDetails'));
    return api({
        method: 'POST',
        url: '/content',
        data: {
            section: `${sectionId}`,
            channel: 1,
            user: 1,
            company: 1,
            appType: appDetails.idAppType,
            app: 1,
        },
    });
}

// Envia o email para recuperacao de senha
export function sendEmailPassword(data) {
    return axios({
        method: 'POST',
        //url: 'http://localhost:3333',
        url: `${environment}/login/forgot`,
        data,
    });
}

// Envia o rating escolhido pelo usuario
export function setRating(data) {
    return api({
        method: 'POST',
        url: `/ranking/vote/`,
        data: data,
    });
}

// Retorna o conteúdo do aluno pelo ID
export function contentList(id) {
    return api({
        method: 'GET',
        url: `/content/list/${id}`,
    });
}

// Retorna a lista de usuários
export function searchUser() {
    return api({
        method: 'GET',
        url: `/users/searchuser`,
    });
}

// Retorna a lista de usuários
export function usersList() {
    return api({
        method: 'GET',
        url: `/users`,
    });
}

export function usersListWithParams(params) {
    return api({
        method: 'GET',
        url: `/users`,
        params,
    });
}

// Retorna o conteúdo do aluno pelo ID
export function findContent(id) {
    return api({
        method: 'GET',
        url: `/content/find/${id}`,
    });
}

// Retorna os dados do aluno pelo ID
export function getUserInfo(id) {
    return api({
        method: 'GET',
        url: `/users/${id}`,
    });
}

// Edita as informações do aluno
export function setUserInfo(id, data) {
    return api({
        method: 'PUT',
        url: `/users/${id}`,
        data: data,
        validateStatus: () => true,
    });
}

// Edita o campo especifico de informacao do aluno
export function setUserField(id, data) {
    return api({
        method: 'PUT',
        url: `/users/editField/${id}`,
        data: data,
    });
}

// Edita as informações do aluno
export function setUserAgreement(id, data) {
    return api({
        method: 'PUT',
        url: `/users/agreement/${id} `,
        data: data,
    });
}

export function acceptResponsabilityTerms({ idTermOfResponsibility, versionNumber }) {
    return api({
        method: 'POST',
        url: `learning/terms/${idTermOfResponsibility}/${versionNumber}`,
    });
}

// SSO NexiMobi
export function getAssessment(url, data) {
    return api({
        method: 'POST',
        url: url,
        data: data,
    });
}

// Salva dados da sala do chat
export function chatSaveRoom(roomId, data) {
    return api({
        method: 'POST',
        url: `/chat/savemessageroom/${roomId}`,
        data: data,
    });
}

// Retorna os dados de uma sala do chat
export function chatLoadRoom(roomId) {
    return api({
        method: 'GET',
        url: `/chat/loadroom/${roomId}`,
    });
}

// Retorna a lista de salas do chat
export function chatListRooms() {
    return api({
        method: 'GET',
        url: `/chat/listrooms/`,
    });
}

// Retorna lista de steps de jornada IMPACT
export function getImpactMenu(id) {
    return api({
        method: 'GET',
        url: `/content/impactmenu/${id}`,
    });
}

export function initTracking(navItem, navType) {
    return api({
        method: 'POST',
        url: '/tracking/enter',
        data: {
            idNavigationItem: `${navItem}`,
            idNavigationType: `${navType}`,
        },
    });
}

export function recordTracking(timeStart, navItem, navType, idSection = null, timeExecuted = 0) {
    return api({
        method: 'POST',
        url: '/tracking/exit',
        data: {
            timeStart: timeStart,
            idNavigationItem: navItem,
            idNavigationType: navType,
            idSection: idSection,
            timeExecuted: timeExecuted,
        },
    });
}

export function uploadImageApi(data) {
    data.append('token', getToken());
    return axios.post('https://upload.bedigital.net.br/uploads/file', data, {
        headers: {
            'Content-Type': `multipart/form-data`,
            accept: 'application/json',
        },
    });
}

export function getProductsLojinha(searchText) {
    return api({
        method: 'POST',
        url: '/store/offers',
        data: { searchText: searchText },
    });
}

export function getPurchasedProductsLojinha(searchText) {
    return api({
        method: 'POST',
        url: '/store/purchased',
        data: { searchText: searchText },
    });
}

export function getCartItensLojinha() {
    return api({
        method: 'GET',
        url: '/store/shoppingcart',
    });
}

export function addProductsToCartLojinha(id) {
    return api({
        method: 'POST',
        url: '/store/addtocart',
        data: { idOffer: id },
    });
}

export function removeProductsToCartLojinha(id) {
    return api({
        method: 'POST',
        url: '/store/removefromcart',
        data: { idOffer: id },
    });
}

export function buyLojinha(data) {
    return api({
        method: 'POST',
        url: '/store/buy',
        data: data,
    });
}

export function getTotalUserPoints() {
    return api({
        method: 'GET',
        url: '/campaign/totaluserpoints',
    });
}

export function getListCampaigns() {
    return api({
        method: 'GET',
        url: '/campaign/listcampaigns',
    });
}

export function listcampaignuserpoints(data) {
    return api({
        method: 'POST',
        url: '/campaign/listcampaignuserpoints',
        data,
    });
}
export function getListCampaignsDetail(id) {
    return api({
        method: 'GET',
        url: `/campaign/listcampaigndetail/${id}`,
    });
}
export function getCampaignsEvents() {
    return api({
        method: 'GET',
        url: `/campaign/listevents/`,
    });
}

export function getSchedulesFirst(setListschedules = null) {
    return api({
        method: 'GET',
        url: '/schedule/listschedules',
    })
        .then((response) => {
            if (response.data.success === true) {
                if (setListschedules != null) {
                    setListschedules(response.data.scheduleResult.schedules);
                }
                return response.data.scheduleResult.schedules;
            }
            if (setListschedules != null) {
                setListschedules([]);
            }
            return [];
        })
        .catch((err) => {
            if (setListschedules != null) {
                setListschedules([]);
            }
            return [];
            throw err;
        });
}

export function getSchedules(filterTypes) {
    return api({
        method: 'GET',
        url: `/schedule/listschedules${filterTypes ? '?scheduleTypes=' + filterTypes : ''}`,
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            throw err;
        });
}

export function getSchedulesTypes(id) {
    return api({
        method: 'GET',
        url: '/schedule/listschedulestype',
    })
        .then((response) => {
            if (response.data.success === true) {
                return response.data.schedulesType;
            }
            return false;
        })
        .catch((err) => {
            return false;
            throw err;
        });
}

export function getGroupList() {
    return api
        .get(`/group/list`)
        .then((response) => {
            if (response.data.success === true) {
                return response.data.groups;
            }

            return [];
        })
        .catch((err) => {
            return [];
            throw err;
        });
}

export function updateYLDuration(idContent, duration) {
    const data = {
        idContentItem: idContent,
        duration: duration,
    };

    return api({
        method: 'POST',
        url: '/content/updateduration',
        data,
    }).then((response) => {
        return response;
    });
}

export function getUploadList(idSection) {
    return api.get(`/userupload/list/${idSection}`);
}

export function contentItensGroups(id, data = '') {
    return api({
        method: 'GET',
        url: `/content/contentgroup/${id + data}`,
        data: data,
    });
}

export function getListContentChild(id) {
    return api({
        method: 'GET',
        url: `/content/listcontentchild/${id}`,
    });
}

export function getnode(id) {
    return api.get(`/learning/nodes/${id}`);
}

export function getEquivalences(idNodeTarget, params) {
    return api({
        method: 'GET',
        url: `/learning/nodes/${idNodeTarget}/equivalences`,
        params,
    });
}

/* PCT */

// Matricula o usuário em uma turma
export function userEnrollments(obj) {
    return api({
        method: 'POST',
        url: '/learning/enrollments/',
        data: obj,
    });
}

// Usuário solicita a matrícula em uma turma.
export function userEnrollmentRequest(obj) {
    return api({
        method: 'POST',
        url: '/learning/enrollments/request',
        data: obj,
    });
}

// API de busca de tags de um branch.
export function getAvailableTags(obj) {
    return api({
        method: 'GET',
        url: `learning/tags/available?nodeCode=${obj.nodeCode}`,
    });
}

// API para realizar busca no catalógo
export function getSearchCatalog(nodeCode, obj) {
    return api({
        method: 'GET',
        url: `/learning/catalogs/${nodeCode}?${new URLSearchParams(obj).toString()}`,
    });
}

// BUSCA URL DO OBJETO SCORM PARA SER LIDO DENTRO O IFRAME
export function scormPlay(obj) {
    return api({
        method: 'POST',
        url: '/learning/scorm/play/',
        data: obj,
    });
}

export function getContentData(idContentItem) {
    return api({
        method: 'GET',
        url: `/content/find/${idContentItem}`,
    });
}

// Cria o Node Enrollments
export function createNodeEnrollments(obj) {
    return api({
        method: 'POST',
        url: '/learning/node-enrollments/',
        data: obj,
    });
}

// Retorna o nó do level pelo id da matricula
export function getLevelNodeEnrollment(idUser, idEnrollment, nodeCode) {
    return api({
        method: 'GET',
        url: nodeCode
            ? `learning/students/${idUser}/enrollments/${idEnrollment}/nodes/${nodeCode}`
            : `learning/students/${idUser}/enrollments/${idEnrollment}`,
    });
}

// Cria o Content Enrollments
export function createContentEnrollments(obj) {
    return api({
        method: 'POST',
        url: '/learning/content-enrollments/',
        data: obj,
    });
}

//TRACKING DO CONTEÚDO
export function learningContentsTracking(obj, idContentItem) {
    return api({
        method: 'POST',
        url: `/learning/contents/${idContentItem}/trackings`,
        data: obj,
    });
}

//INICIA A AVALIAÇÃO
export function initEvaluation(obj) {
    return api({
        method: 'POST',
        url: `/learning/students/${obj.idUser}/enrollments/${obj.idEnrollment}/evaluations/${obj.idEvaluation}/questionnaires`,
        data: {
            idNodeEnrollment: obj.idNodeEnrollment,
        },
    });
}

export function getEvaluationQuestionnaires(obj) {
    return api({
        method: 'GET',
        url: `learning/students/${obj.idUser}/enrollments/${obj.idEnrollment}/evaluations/${obj.idEvaluation}/questionnaires/${obj.idQuestionnaire}`,
    });
}

//UPDATE DO STATUS DOS CONTEÚDOS
export function learningContentUpdate(obj, idContentEnrollment) {
    return api({
        method: 'PUT',
        url: `/learning/content-enrollments/${idContentEnrollment}`,
        data: obj,
    });
}

// Retorna o conteúdo do aluno pelo ID
export function findContentStudents(idUser, idEnrollment, idContentItem, idNodeEnrollment) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/nodeEnrollment/${idNodeEnrollment}/contents/${idContentItem}`,
    });
}

// Retorna os objetos em andamento
export function getUserEnrollment() {
    return api({
        method: 'GET',
        url: `/learning/catalogs/enrollments`,
    });
}

// Retorna os objetos em andamento
export function getUserEnrollmentInprogress(idUser) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/enrollments-in-progress`,
    });
}

//  AVALIAÇÃO DE REAÇÃO

export function postQuestionnairesSave(obj, idUser, idEnrollment, idEvaluation, idQuestionnaire) {
    return api({
        method: 'POST',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/save`,
        data: obj,
    });
}

export function postQuestionnairesFinish(obj, idUser, idEnrollment, idEvaluation, idQuestionnaire) {
    return api({
        method: 'POST',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/finish`,
        data: obj,
    });
}

// CERTIFICADO

export function getCertificate(obj) {
    return api({
        method: 'POST',
        url: `/certificate`,
        data: obj,
        headers: { Accept: 'application/pdf' },
        responseType: 'blob',
        timeout: 30000,
    });
}

export function getUserLoginInfo(username, idApp, idCompany) {
    let url = `/login/user-login-info?username=${username}`;
    if (typeof idApp == 'number' && idApp !== 0) {
        url = url + `&idApp=${idApp}`;
    }
    if (typeof idCompany == 'number' && idCompany !== 0) {
        url = url + `&idCompany=${idCompany}`;
    }
    return api({
        method: 'GET',
        url: url,
    });
}

//RETORNA DADOS PARA MONTAR O MAPA DOS CONTEÚDOS DENTRO DO NÓ.
export function getMapFromEnrollment(obj) {
    let isEmptyObject = true;
    let paramsObj = {};

    for (const property in obj) {
        if (obj[property] && property !== 'idEnrollment' && property !== 'idUser') {
            paramsObj[property] = obj[property];
            isEmptyObject = false;
        }
    }

    return api({
        method: 'GET',
        url: isEmptyObject
            ? `learning/students/${obj.idUser}/enrollments/${obj.idEnrollment}/navigation-map`
            : `learning/students/${obj.idUser}/enrollments/${obj.idEnrollment}/navigation-map?${new URLSearchParams(paramsObj).toString()}`,
    });
}

export function getEmail(type) {
    return api({
        method: 'GET',
        url: `/notifications/emails/${type}`,
    });
}

export function postEmail(type, obj) {
    return api({
        method: 'POST',
        url: `/notifications/emails/${type}`,
        data: obj,
    });
}

//ENDPOINT DE PESQUISA

export function getSearch(obj) {
    return api({
        method: 'GET',
        url: `/learning/search?${new URLSearchParams(obj).toString()}`,
    });
}

export function getTree(nodeCode, nodeType = 'ORGANIZATION', status = 'PUBLISHED') {
    return api({
        method: 'GET',
        url: `/learning/nodes/tree?nodeCode=${nodeCode}&nodeType=${nodeType}&status=${status}`,
    });
}

export function currentUserEnrollments(idUser) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/enrollments`,
    });
}

export const startCDN = () => {
    return axios.get(process.env.REACT_APP_SCORM_CDN_TEST);
};

//ENDPOINT AVALIAÇÃO DE CONHECIMENTO

export function getKnowledgeQuestions(obj) {
    return api({
        method: 'GET',
        url: `/learning/questions?${new URLSearchParams(obj).toString()}`,
    });
}

export function getKnowledgeEvaluationDetails(idEvaluation) {
    return api({
        method: 'GET',
        url: `/learning/evaluations/${idEvaluation}`,
    });
}

//Endpoint para responder uma questão.
export function setKnowledgeEvaluationQuestionAnswer(idUser, idEnrollment, idEvaluation, idQuestionnaire, idAnswer, dataRequest) {
    return api({
        method: 'PUT',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/answers/${idAnswer}`,
        data: dataRequest,
        validateStatus: () => true,
    });
}

//Endpoint retorna o resultado do questionário e o gabarito das questões
export function getknowledgeEvaluationQuestionResult(idUser, idEnrollment, idEvaluation, idQuestionnaire) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/result`,
    });
}

//Endpoint que atualiza o tempo restante do questionário
export function updateEvaluationTime(idUser, idEnrollment, idEvaluation, idQuestionnaire, leftTime) {
    return api({
        method: 'PUT',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/time`,
        data: {
            timeLeft: leftTime,
        },
        validateStatus: () => true,
    });
}

//Endpoint retorna o resultado do questionário e o gabarito das questões
export function getknowledgeEvaluationAnswerSheetQuestion(idUser, idEnrollment, idEvaluation, idQuestionnaire, idAnswer) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}/answers/${idAnswer}/result`,
    });
}

export function getListCampaignsActive() {
    return api({
        method: 'GET',
        url: '/campaign/listcampaigns',
        params: {
            onlyActive: true,
        },
    });
}

export function getListCampaignpointsSummary({ idCampaign, offset, limit, department }) {
    return api({
        method: 'GET',
        url: `/campaign/listcampaignpointssummary/${idCampaign}`,
        params: {
            offset: offset,
            limit: limit,
            department: department || '',
        },
    });
}

export function getCampaignDepartments({ idCampaign }) {
    return api({
        method: 'GET',
        url: `/campaign/${idCampaign}/departments`,
    });
}

export function getDataCampaignUser({ idCampaign, idUser, department }) {
    return api({
        method: 'GET',
        url: `/campaign/${idCampaign}/users/${idUser}`,
        params: {
            department: department || '',
        },
    });
}

export function getCategoriesCampaign({ id }) {
    return api({
        method: 'GET',
        url: `learning/categories/campaign/${id}`,
    });
}

export function getCampaignListByUser() {
    return api({
        method: 'GET',
        url: `/campaign/listbyuser`,
    });
}

export function postCampaignNotified({ idCampaign, idUser }) {
    return api({
        method: 'POST',
        url: `/campaign/${idCampaign}/users/${idUser}/notified`,
    });
}

export function getCampaignListByContent({ idElement }) {
    return api({
        method: 'GET',
        url: `/campaign/listbycontent/${idElement}`,
    });
}

export function updateStatusLike(method, elementType, id) {
    return api({
        method: method, // 'POST | DELETE'
        url: `/learning/social/likes/${elementType}/${id}`,
    });
}

export function getLearningTags(params) {
    return api({
        method: 'GET',
        url: `/learning/tags/`,
        params: params,
    });
}

export function getLearningTagsUser(idUser) {
    return api({
        method: 'GET',
        url: `/learning/tags/users/${idUser}`,
    });
}

export function postLearningTagsUser(idUser, data) {
    return api({
        method: 'POST',
        url: `/learning/tags/users/${idUser}`,
        data: data,
    });
}

export function getLearningRecommendations(idUser, params) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/recommendations`,
        params: params,
    });
}

export function getEnrollmentRequests(params) {
    return api({
        method: 'GET',
        url: `/learning/enrollment-requests/`,
        params: params,
    });
}

export function cancelEnrollmentRequests(idEnrollmentRequest) {
    return api({
        method: 'PUT',
        url: `/learning/enrollment-requests/${idEnrollmentRequest}/cancel`,
    });
}

export function getUserIsInCampaign() {
    return api({
        method: 'GET',
        url: `/campaign/userisincampaign`,
    });
}

export function getHasEnrollmentRequests(idUser) {
    return api({
        method: 'GET',
        url: `/learning/students/${idUser}/has-enrollment-requests`,
    });
}

//ENDPOINT DE UPGRADE

export const getLicenses = () => {
    return api({
        method: 'GET',
        url: `/learning/licenses`,
    });
};

export const postUpgradeLicenses = (data) => {
    return api({
        method: 'POST',
        url: `/learning/licenses/upgrade`,
        data,
    });
};

export const getLicensesWithId = (id) => {
    return api({
        method: 'GET',
        url: `/learning/licenses/{id}`,
    });
};

export const postRequestUpgrade = (id) => {
    return api({
        method: 'post',
        url: `/learning/licenses/upgrade-request`,
    });
};

export const getRequestCount = () => {
    return api({
        method: 'GET',
        url: `/learning/licenses/upgrade-request`,
    });
};

export const getLicenseRequestStatus = () => {
    return api({
        method: 'GET',
        url: `/learning/licenses/already-requested`,
    });
};

//logs shared

export const postLogsShare = (data) => {
    return api({
        method: 'post',
        url: `/logs`,
        data,
    });
};

//plans

export const getTrialPlans = (withDetails) => {
    return api({
        method: 'GET',
        url: `learning/plans/`,
        params: { withDetails: withDetails },
    });
};

export const postRequestTrial = (data) => {
    return api({
        method: 'POST',
        url: `/learning/plans/request-trial`,
        data,
    });
};

export const postContactTrial = (data) => {
    return api({
        method: 'POST',
        url: `/learning/plans/contact-trial`,
        data,
    });
};

export const postExtendTrial = (data) => {
    return api({
        method: 'POST',
        url: `/learning/plans/extend-trial`,
        data,
    });
};

export const postPlanContactRequest = (data) => {
    return api({
        method: 'POST',
        url: `learning/plans/plan-contact-request`,
        data,
    });
};

export const getNumberRequest = (id) => {
    return api({
        method: 'GET',
        url: `learning/plans/${id}`,
    });
};

export const getRequestPlan = (data) => {
    return api({
        method: 'POST',
        url: `learning/plans/request-plan`,
        data,
    });
};

export const getActiveCampaigns = () => {
    return api({
        method: 'GET',
        url: `learning/plans/campaigns`,
    });
};

export function changePassword(idUser, data) {
    return api({
        method: 'PUT',
        url: `/users/${idUser}/change-password`,
        data: data,
    });
}

export function getFeatures(featureName) {
    return api({
        method: 'GET',
        url: `/learning/features/${featureName}`,
    });
}

export function postNotificationsConfigurationsUsers(obj, idUser) {
    return api({
        method: 'POST',
        url: `/notifications/configurations/users/${idUser}`,
        data: obj,
    });
}

export function getCommentsByEntity(entityType, idEntity, params) {
    return api({
        method: 'GET',
        url: `/learning/comments/${entityType}/${idEntity}`,
        params,
    });
}

export function addCommentByEntity(entityType, idEntity, data) {
    return api({
        method: 'POST',
        url: `/learning/comments/${entityType}/${idEntity}`,
        data,
    });
}

export function updateCommentByEntity(entityType, idEntity, idComment, data) {
    return api({
        method: 'PUT',
        url: `/learning/comments/${entityType}/${idEntity}/${idComment}`,
        data,
    });
}

export function reportCommentByEntity(entityType, idEntity, data) {
    return api({
        method: 'POST',
        url: `/learning/comments/${entityType}/${idEntity}/report`,
        data,
    });
}

export function deleteCommentByEntity(entityType, idEntity, idComment) {
    return api({
        method: 'DELETE',
        url: `/learning/comments/${entityType}/${idEntity}/${idComment}`,
    });
}

export default api;
