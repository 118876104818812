import './style.scss';
import React, { useEffect, useState } from 'react';
import { Text, Button } from 'pctComponents/atoms';
import { PlaneProps, ViewScreen } from '../../@model/ManagerTrial.model';
import { useManagerTrial } from '../../context/contextManagerTrial';
import { IoIosPeople } from 'react-icons/io';
import { formatValueReal } from 'services/utils';

const priceTiers = Object.freeze([
    { maxUsers: 100, unitPrice: 55 },
    { maxUsers: 250, unitPrice: 50 },
    { maxUsers: 500, unitPrice: 45 },
    { maxUsers: 2500, unitPrice: 35 },
    { maxUsers: 5000, unitPrice: 25 },
    { maxUsers: Infinity, unitPrice: 20 },
]);

const tiersTable = Object.freeze([
    { tier: '100', users: 0, unitValue: '', monthlyValue: '' },
    { tier: '101-250', users: 0, unitValue: '', monthlyValue: '' },
    { tier: '251-500', users: 0, unitValue: '', monthlyValue: '' },
    { tier: '501-2500', users: 0, unitValue: '', monthlyValue: '' },
    { tier: '2501-5000', users: 0, unitValue: '', monthlyValue: '' },
    { tier: '> 5000', users: 0, unitValue: '', monthlyValue: '' },
    { tier: 'Total', users: 0, unitValue: '', monthlyValue: '' },
]);

const CalculatorPlanUpgrade = ({}) => {
    const [showDetailCalc, setShowDetailCalc] = useState(false);
    const [numberUsers, setNumberUsers] = useState(0);
    const [calcTable, setCalcTable] = useState(tiersTable);
    const [otherPlans, setOtherPlans] = useState<PlaneProps[]>([]);
    const [totalMonthlyPrice, setTotalMonthlyPrice] = useState(0);
    const { trialStatusPlan, currentPlan, setViewScreenPlan, setCurrentPlan, listPlans } = useManagerTrial();
    const { deadline } = currentPlan as PlaneProps;

    useEffect(() => {
        if (numberUsers > 0) {
            doCalc();
        } else {
            setShowDetailCalc(false);
        }
    }, [numberUsers]);

    useEffect(() => {
        getOtherPlans();
    }, []);

    const planText = {
        screen: 'CalculatorPlanUpgrade',
        trialStatusPlan: trialStatusPlan,
        deadline,
    };

    const getOtherPlans = () => {
        const areOtherPlans = (listPlans || []).filter((plan) => plan.idPlan !== currentPlan?.idPlan);
        setOtherPlans(areOtherPlans as PlaneProps[]);
    };

    const calculatePrice = (numberOfEmployees, _calcTable) => {
        if (!calcTable || !priceTiers || numberOfEmployees === 0) {
            return;
        }
        let _totalMonthlyPrice = 0;
        for (let i = 0; i < priceTiers.length; i++) {
            const currentTier = priceTiers[i];
            let usersInTier = 0;

            if (i === 0) {
                usersInTier = Math.min(numberOfEmployees, currentTier.maxUsers);
            } else {
                usersInTier = Math.min(numberOfEmployees, currentTier.maxUsers - priceTiers[i - 1].maxUsers);
            }

            if (usersInTier > 0) {
                _calcTable[i].users = usersInTier;
                _calcTable[i].unitValue = formatValueReal(currentTier.unitPrice);
                _calcTable[i].monthlyValue = formatValueReal(usersInTier * currentTier.unitPrice);
                _totalMonthlyPrice += usersInTier * currentTier.unitPrice;

                numberOfEmployees -= usersInTier;
            }
        }

        _calcTable[_calcTable.length - 1].monthlyValue = formatValueReal(_totalMonthlyPrice);
        _calcTable[_calcTable.length - 1].users = numberUsers;

        setCalcTable([..._calcTable]);
        setShowDetailCalc(true);
        setTotalMonthlyPrice(_totalMonthlyPrice);
    };

    const doCalc = () => {
        setShowDetailCalc(false);
        setCalcTable([]);
        calculatePrice(numberUsers, [...tiersTable]);
    };

    const buyPlan = () => {
        setViewScreenPlan(ViewScreen.detail);
        setCurrentPlan({
            ...currentPlan,
            numberOfEmployees: numberUsers,
            totalMonthlyPrice,
        } as PlaneProps);
    };

    const filterCalcTable = (calc, index) => {
        if (calc.users === 0) {
            return false;
        }

        if (calc.tier === 'Total' && !isNaN(calc.users)) {
            return true;
        }

        const range = calc.tier.split('-').map(Number);
        if (index === 0 && numberUsers < 100) {
            return true;
        } else if (range.length === 1 && numberUsers >= range[0]) {
            return true;
        } else if (range.length === 2 && numberUsers >= range[0]) {
            return true;
        } else if (numberUsers > 5000) {
            return true;
        }
        return false;
    };

    return (
        <div className="pctCalculatorPlanUpgrade">
            <div className="pctCalculatorWrapper">
                <div className={`pctSelectedPlaItemCurrent`}>
                    <Text component={'h2'} text={'Qual o tamanho da sua equipe?'} />
                    <Text
                        component={'p'}
                        text={'Por favor, indique o tamanho da sua equipe para que possamos calcular a faixa de preço para você.'}
                    />
                    <Text component={'p'} className="subtitle" text={'Faixas de preço por quantidade de usuários'} />
                    <div className="tableBaseValue">
                        <table>
                            <tbody>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> Até 100
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$55,00</td>
                                </tr>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> de 101 à 250
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$50,00</td>
                                </tr>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> de 251 à 500
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$45,00</td>
                                </tr>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> de 501 à 2500
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$35,00</td>
                                </tr>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> de 2501 à 5000
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$25,00</td>
                                </tr>
                                <tr>
                                    <td data-label="Faixas por quant.">
                                        <span>&#8226;</span> mais de 5000
                                    </td>
                                    <td className="lineTable">
                                        <hr />
                                    </td>
                                    <td data-label="Valor unitário">R$20,00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="pctSelectedPlaItemInfo">
                    <Text component={'h2'} text={'Plano <span>light</span>'} />
                    <div className="numberUsers">
                        <Text component={'p'} text={'Quantidade de usuários:'} />
                        <div className="numberUsersInput">
                            <input
                                type="number"
                                id="numberUsers"
                                name="numberUsers"
                                min="1"
                                value={numberUsers}
                                onChange={(e) => {
                                    setNumberUsers(parseInt(e.target.value));
                                }}
                            />
                            {/* <Button callEvent={() => doCalc()} className={`button06`} text={`Confirmar quantidade`} disabled={!numberUsers || numberUsers <= 0}/> */}
                        </div>
                    </div>
                    {showDetailCalc ? (
                        <div className="tableCalculatorValue">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Faixas por quant.</th>
                                        <th scope="col">Usuários</th>
                                        <th scope="col">Valor unitário</th>
                                        <th scope="col">Valor mensal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {calcTable.filter(filterCalcTable).map((data) => {
                                        return (
                                            <tr key={data.tier}>
                                                <td data-label="Faixas por quant.">{data.tier}</td>
                                                <td data-label="Usuários" className={data.tier === 'Total' ? 'gold' : ''}>
                                                    {data.users}
                                                </td>
                                                <td data-label="Valor unitário">{data.unitValue}</td>
                                                <td data-label="Valor mensal" className={data.tier === 'Total' ? 'gold' : ''}>
                                                    {data.monthlyValue}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="alertCalculatorPlan">
                            <IoIosPeople />
                            <Text component={'h4'} text={'Escolha o tamanho da sua equipe'} />
                            <Text
                                component={'p'}
                                text={
                                    'Defina a quantidade de usuários para que possamos calcular o preço por faixa e dar continuidade ao processo de compra.'
                                }
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="buttonsWrapper">
                {otherPlans.length > 0 && (
                    <Button
                        callEvent={() => {
                            setViewScreenPlan(ViewScreen.upgrade);
                        }}
                        className={`button01`}
                        text={`Ver planos`}
                    />
                )}
                {showDetailCalc && <Button callEvent={buyPlan} className={`button06`} text={`Continuar`} />}
            </div>
        </div>
    );
};

export default CalculatorPlanUpgrade;
