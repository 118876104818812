import "./style.scss";

import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Preloader } from "../../atoms";
import { scormPlay, getContentData, getLevelNodeEnrollment, learningContentUpdate } from "../../../services/api";
import { useHistory } from "react-router-dom";
import useBackControll from "../../../pctHooks/useBackControll";
import { updateConfigContentsMap, updateConfigHeaderFooter, updateConfigCampaign } from "../../../actions";
import { useCookies } from "react-cookie";
import { SCORM_CDN_TEST, getProfileInfo } from "services/utils";


type idType = {
  idEnrollment?: string;
  idContentItem?: string;
  idSco?: string;
  idNodeEnrollment?: string;
  scoType?: string;
  scormCompleted?: string;
  idTeam?: string;
};

const ContentScorm = (props) => {
  const { updateConfigHeaderFooter, updateConfigContentsMap, updateConfigCampaign } = props;
  const history = useHistory();
  const {
    idEnrollment,
    idContentItem,
    idSco,
    idNodeEnrollment,
    scoType,
    scormCompleted,
    idTeam,
  } = useParams<idType>();
  const iframePlayerRef: MutableRefObject<HTMLIFrameElement | null> =
    useRef<HTMLIFrameElement>(null);
  const currentScoIndex = useRef(0);
  const isPlayed = useRef(false);
  const [loading, setLoading] = useState(true);
  const [scormInfos, setScormInfos] = useState({
    rootContent: null,
    callLmsFinish: null,
    scormUrl: "",
    objScorm: {},
    currentScoObject: {},
    nextItem: { sequential: false },
    previousItem: null,
    currentItem: {},
    nodeCode: null
  });


  const [cookies] = useCookies([SCORM_CDN_TEST.COOKIE_NAME]);
  const useCdn: boolean =
    cookies[SCORM_CDN_TEST.COOKIE_NAME] === SCORM_CDN_TEST.PASSED;

  const { backControllFn } = useBackControll({});



  const playScorm = (obj) => {
    scormPlay({
      idEnrollment: idEnrollment,
      idContentItem: idContentItem,
      idSco: idSco,
      idNodeEnrollment: idNodeEnrollment,
      useCdn,
    })
      .then((response) => {
        if (response) {
          obj.rootContent = {
            idEnrollment: idEnrollment,
            idContentItem: idContentItem,
            idNodeEnrollment: idNodeEnrollment,
            idTeam: idTeam,
          };
          obj.callLmsFinish = callLmsFinish;
          obj.scormUrl = response.data.url;
          obj.nextItem = response.data.nextItem;
          obj.previousItem = response.data.previousItem;
          obj.currentItem = response.data.currentItem;
          obj.nodeCode = response.data.currentItem
            ? response.data.currentItem.nodeCode
            : null;
          setScormInfos(obj);
        } else {
          //TODO: TRATAR ERRO DO CONTEÚDO DO PLAYER DO SCORM.
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const callLmsFinish = (closeContent = true) => {
    updateConfigContentsMap({
      forceCurrentComplete: false,
    });

    if (iframePlayerRef.current && iframePlayerRef.current.contentWindow) {
      iframePlayerRef.current.contentWindow.postMessage("callLmsFinish", "*");
      // CHAMAR O MODAL DE LOADING DIZENDO QUE AS INFOS ESTÃO SENDO SALVAS E NO FINAL VOLTAR PARA A PÁGINA DOS SCOS.
      setLoading(true);
    }

    if (closeContent) {
      setTimeout(() => {
        if (scoType === "single") {
          backControllFn(history);
        } else {
          const routerLink = `/lms/${idEnrollment}/${idContentItem}/${idNodeEnrollment}/1`;
          history.push(routerLink);
        }

        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  };

  const updateLessonIndex = () => {
    scormInfos.objScorm["scoItens"].map((element: any, i: number) => {
      if (element.idSco === scormInfos.currentScoObject["idSco"]) {
        currentScoIndex.current = i + 1;
      }
    });
  };


  const isTheLastEnrollmentContentToCompleteCampaign = async (e) => {
    // VERIFICANDO SE O SCO É O ÚLTIMO, SE O TREINAMENTO A SER FINALIZADO TAMBÉM É O ÚLTIMO (Matricula) E SE EXISTE CAMPANHA.
    return new Promise((resolve, reject) => {
      getLevelNodeEnrollment(getProfileInfo().idUser, idEnrollment, scormInfos.nodeCode)
      .then((response) => {
        if (response) {
          
          const hasCampaign = response.data.node.hasCampaign;
          const isLastEnrollmentContent = scormInfos.currentItem ? scormInfos.currentItem["isLastContent"] : false;
          const isTheLastSco = currentScoIndex.current == scormInfos.objScorm["scoItens"].length ? true : false;
        
          // console.log(response.data)
          // console.log(scormInfos);
          // console.log(hasCampaign, isLastEnrollmentContent, isTheLastSco)

          if(hasCampaign == true && isLastEnrollmentContent == true && isTheLastSco == true){
            resolve(true);
          }else{
            resolve(false);
          }

        } else {
          throw new Error("Enrollment data not found.");
        }

        
      })
      .catch((error) => {
        console.log(error);
      });
    })

  };

  useEffect(() => {
    if (scormInfos.objScorm.hasOwnProperty("scoItens")) {
      updateLessonIndex();
      updateConfigHeaderFooter({
        logoStyle: scormInfos.currentScoObject["title"] ? `hide` : "show",
        prevNavStyle: !(scoType === "single") ? "prevShow" : "closeShow",
        backCloseText: !(scoType === "single")
          ? "VOLTAR PARA AS LIÇÕES"
          : "FECHAR CONTEÚDO",
        titleHeader: scormInfos.currentScoObject["title"]
          ? `${scormInfos.currentScoObject["title"]}`
          : null,
        lessonHeader: !(scoType === "single")
          ? `Lição ${currentScoIndex.current} / ${scormInfos.objScorm["scoItens"].length}`
          : null,
        footerStyle: "hide",
        contentNavigation: scoType === "single" ? scormInfos : null,
        unlockNext:
          scormCompleted === "true"
            ? true
            : scormInfos.nextItem
            ? scormInfos.nextItem.sequential
              ? false
              : true
            : true,
        closeFn: callLmsFinish,
      });
    }

    const handlerMessage = async(e: MessageEvent) => {
      //O PLAYER SCORM ENVIA A MENSAGEM QUANDO O CONTEÚDO É FINALIZADO.
      if (e.data === "scormFinished") {
        updateConfigHeaderFooter({
          logoStyle: scormInfos.currentScoObject["title"] ? `hide` : "show",
          prevNavStyle: !(scoType === "single") ? "prevShow" : "closeShow",
          backCloseText: !(scoType === "single")
            ? "VOLTAR PARA AS LIÇÕES"
            : "FECHAR CONTEÚDO",
          titleHeader: scormInfos.currentScoObject["title"]
            ? `${scormInfos.currentScoObject["title"]}`
            : null,
          lessonHeader: !(scoType === "single")
            ? `Lição ${currentScoIndex.current} / ${scormInfos.objScorm["scoItens"].length}`
            : null,
          footerStyle: "hide",
          contentNavigation: scoType === "single" ? scormInfos : null,
          unlockNext: true,
          closeFn: callLmsFinish,
        });

        const isTheLastSco = currentScoIndex.current == scormInfos.objScorm["scoItens"].length ? true : false;

        if(isTheLastSco){
          //Tem que ver se é o último sco para verificar a finalização da matrícula.
          updateConfigContentsMap({
            forceCurrentComplete: true
          });

          const isTheLastEnrollmentContent = await isTheLastEnrollmentContentToCompleteCampaign(e);

          if(isTheLastEnrollmentContent === true){
              updateConfigCampaign({
                earnPointsStatus: true,
              });
          }

        }
        

        updateConfigContentsMap({
          forceCurrentComplete: true,
        });
      }
    };

    window.addEventListener("message", handlerMessage);

    return () => {
      window.removeEventListener("message", handlerMessage);
    };
  }, [scormInfos]);

  useEffect(() => {
    const beforeunloadListener = (e) => {
      if (iframePlayerRef.current && iframePlayerRef.current.contentWindow) {
        const iframeWindow: any = iframePlayerRef.current?.contentWindow;

        e.returnValue = "";
        iframeWindow.postMessage("callLmsFinish", "*");

        window.removeEventListener("beforeunload", beforeunloadListener);
        e.preventDefault();
      } else {
        window.removeEventListener("beforeunload", beforeunloadListener);
      }
    };

    window.addEventListener("beforeunload", beforeunloadListener);
  }, [iframePlayerRef.current]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isPlayed.current) {
        isPlayed.current = true;

        getContentData(idContentItem)
          .then((response) => {
            const objScorm = response.data.content[0].scorm;
            const currentScoObject = objScorm.scoItens.filter(
              (obj) => obj.idSco == idSco
            )[0];

            playScorm({
              objScorm: objScorm,
              currentScoObject: currentScoObject,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);

    return () => {

      updateConfigCampaign({
        earnPointsStatus: false,
      });

      updateConfigHeaderFooter({
        scoSingle: false,
      });

      clearTimeout(timeout);
    };
    
  }, []);

  return (
    <>
      {loading ? <Preloader className="pctPreloaderBlur" /> : null}

      <div className="pctPage pctContentScorm">
        <iframe
          ref={iframePlayerRef}
          src={scormInfos.scormUrl}
          height="100%"
          width="100%"
          onLoad={() => {
            setLoading(false);
          }}
          allowFullScreen={true}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
  updateConfigContentsMap: (item) => dispatch(updateConfigContentsMap(item)),
  updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(ContentScorm);
