import React from "react";

import { Like } from "pctComponents/atoms";
import { ContentNavigationBtn, ContentTreeMap } from "pctComponents/molecules";

function HeaderContentNavigation({ configHeaderFooter, scoSingleContent }) {
  return configHeaderFooter.contentNavigation ? (
    <div className="contentNavigation">
      {configHeaderFooter.contentNavigation.content ? (
        <Like
          direction="invert"
          collection={{
            data: configHeaderFooter.contentNavigation.content,
            elementType: "CONTENT",
            idElement:
              configHeaderFooter.contentNavigation.content.idContentItem,
          }}
          className="likeNav"
        />
      ) : scoSingleContent ? (
        <Like
          direction="invert"
          collection={{
            data: scoSingleContent,
            elementType: "CONTENT",
            idElement: scoSingleContent.idContentItem,
          }}
          className="likeNav"
        />
      ) : null}

      <ContentNavigationBtn
        direction="prev"
        text="Anterior"
        collection={configHeaderFooter.contentNavigation}
      />

      <ContentNavigationBtn
        direction="next"
        text="Próximo"
        unlockNext={configHeaderFooter.unlockNext}
        collection={configHeaderFooter.contentNavigation}
      />

      {/* BOTÃO PARA ACESSAR O MAPA DE CONTEÚDOS */}
      <ContentTreeMap collection={configHeaderFooter.contentNavigation} />
    </div>
  ) : (
    <></>
  );
}

export default HeaderContentNavigation;
